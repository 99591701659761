<template>
  <div class='task-hall'  @click='cancelType'>
    <div class='task-nav'>
      <div class='nav-left'>
        <div class='actived' @click='getProduction(1)'>全部</div>
        <div>
          <span @click.stop='showType'>类别</span>
          <p v-show='isType'>
            <span v-for='(item, key) in type' :key='key' @click.stop='clickType(item.id, "type")'>
              <img src="../../assets/images/selected.png" alt="" v-if='typeSelected.includes(item.id)'>
              <img src="../../assets/images/no-selected.png" alt="" v-else>
              {{item.name}}
            </span>
          </p>
        </div>
        <div @click='toChangePrice'>价格
          <span v-if='sortAsc && sortAsc === "asc"'>↑</span>
          <span v-if='sortAsc && sortAsc === "desc"'>↓</span>
        </div>
      </div>
      <div style='display: flex;'>
        <div class='nav-right' @click='isShare=true'>
          设置提醒
        </div>
        <div class='nav-task' @click='toUpload("https://www.jubenchaoshi.cn/htmlapi/from/task")'>
          发布任务
        </div>
        <div class='search-input-box'>
          <input type="text" placeholder="请输入关键字" class="search-input" v-model="searchVal" @change='searchSort(1)'>
          <img src='https://www.jubenchaoshi.cn/img/so.png'/>
        </div> 
      </div>
      
    </div>
    <div class='task-list'>
      <div class='box-column' @click="goDetail(item.id)" v-for='(item, key) in taskList' :key='key'>
        <img :src="item.img" alt="" class='box-img'>
        <div class='column-msg'>
          <div class='column-type'>{{item.lx}}</div>
          <div class='column-name'>{{item.title}}</div>
          <div class='column-collection'>
            <p>已报名人数: {{item.hot}}</p>
            <img v-if='item.sc' src="https://www.jubenchaoshi.cn/img/scy.png" alt="" @click.stop='toCollect(item.id, key)'>
            <img v-if='!item.sc' src="https://www.jubenchaoshi.cn/img/sc.png" alt="" @click.stop='toCollect(item.id, key)'>
          </div>
        </div>
        <div class='box-bottom'>
          <div>
            <p class='jq'>{{item.price}}</p>
            <p class='price'>任务价格</p>
          </div>
          <div>
            <p>{{item.itemtime}}</p>
            <p class='price'>结束时间</p>
          </div>
        </div>
      </div>
    </div>
    <div class='page-content'>
      <div @click='toPage("index")'>首页</div>
      <div @click='toPage("prev")'>上一页</div>
      <!-- <div v-for='(item, key) in total' :key='key' @click='toPage(key)'>{{key+1}}</div> -->
      <span v-for='(item, key) in total' :key='key' @click="goUrl(key+1)" class='xhy'>
          <div v-if='key+1>actived&&key+1<actived+3'>{{key+1}}</div>
          <div v-if='key+1<actived&&key+1>actived-3'>{{key+1}}</div>
          <div v-if='key+1==actived' class='xzys'>{{key+1}}</div>
          <span v-else class='bxs'></span>
      </span>
      <div @click='toPage("next")'>下一页</div>
      <div @click='toPage("end")'>尾页</div>
      <p>当前第{{actived}}页/共{{total}}页</p>
    </div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="codeimg" alt="" class='mask-img'>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      taskList: [],
      actived: 1,
      total: 0,
      type: [],
      typeSelected: [],
      searchVal: '',
      sortAsc: '',
      isType: false,
      isShare: false,
      codeimg: ''
    }
  },
  methods: {
    toUpload(url) {
      this.$toast('发布任务成功', 'none')
      window.open(url)
    },
    cancelType() {
      this.isType = false
    },
    toCollect(id, key) {
      this.$http.get('/collect/item', {  
        id,
        cid: 4,
      }).then((res) => {
        this.taskList[key].sc = res.rows
        this.$toast(res.msg, 'none')
      })
    },
    showType() {
      if (this.isType) {
        this.isType = false
      } else {
        this.isType = true
      }
    },
    clickType(key) {
      if (this.typeSelected.includes(key)) {
          this.typeSelected.splice(this.typeSelected.indexOf(key), 1)
        } else {
          this.typeSelected.push(key)
        }
      this.isType = false
      this.searchSort(1)
    },
    toChangePrice() {
      if (!this.sortAsc) {
        this.sortAsc = 'desc'
      } else {
        this.sortAsc === 'desc' ? this.sortAsc = 'asc' : this.sortAsc = 'desc'
      }
      this.searchSort()
    },
    searchSort(num) {
      this.actived = num
      let params = {  
        pageSize: 10,
        pageNum: this.actived
      }
      if(this.searchVal) params.title = this.searchVal
      if(this.sortAsc){
        params.orderByColumn = 'price'
        params.isAsc = this.sortAsc
      }
      if(this.typeSelected) params.lx = this.typeSelected.join(',')

      this.$http.get('/taskhall/item', params).then((res) => {
        this.taskList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg

      })
    },
    goUrl(id) {
      this.actived = id;
      this.searchSort(this.actived)
      window.scrollTo( 0, 0 );
    },
    toPage(type) {
      switch (type) {
        case 'index':
          this.actived = 1;
          break;
        case 'prev':
          this.actived = this.actived > 1 ? this.actived-1 : 1;
          break;
        case 'next':
          this.actived = this.actived+1;
          break;
        case 'end':
          this.actived = this.total;
          break;
      }
      this.searchSort(1)
      window.scrollTo( 0, 0 );
    },
    goDetail(id) {

      let routedata = this.$router.resolve({
        path: '/taskHall/detail',
        query: {
          id,
        }
      })
      window.open(routedata.href,'_blank');

      //this.$router.push({
      //  path:'/taskHall/detail',
      //  query: {
      //    id
      //  }
      //})
    },
    getProduction(isClear) {
      if (isClear) {
        this.typeSelected = []
        this.searchVal = ''
        this.sortAsc = ''
        this.isType = false
      }
      this.$http.get('/taskhall/item', {  
        pageSize: 10,
        pageNum: this.actived
      }).then((res) => {
        this.taskList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg

      })
    },
    // 获取类型
    getType() {
      this.$http.get('/tag/renwu', {  
      }).then((res) => {
       this.type = res.rows;
      })
    }
  },
  created() {
    this.getProduction()
    this.getType()
  }
}
</script>

<style scoped lang='less'>
.jq{
  height:25px;
  width:130px;
  overflow:hidden;
}
.task-list{
  overflow:hidden;
}

.column-name{
  width: 228px;
  height: 25px;
  overflow:hidden;
}
.xzys{
  background:#ECBC3B;
}
.task-hall {
  width: 1210px;
  margin: 0 auto;
  .task-list {
    display: flex;
    flex-wrap: wrap;
    .box-column {
      border-radius: 6px;
      border: 1px solid #ddd;
      position: relative;
      margin-top: 30px;
      background: #fff;

      &:not(:nth-child(4n+1)) {
        margin-left: 30px;
      }
      .box-img {
        width: 278px;
        height: 200px;
        display: block;
      }

      .box-bottom {
        display: flex;
        border-top: 1px solid #ddd;
        div {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #e02020;
          line-height: 30px;

          &:not(:first-child) {
            border-left: 1px solid #ddd;
          }

          p {
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: center;
            color: #e02020;
            line-height: 22px;
          }

          .price {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 17px;
          }
        }
      }

      .column-msg {
        padding: 5px 10px 10px 10px;
      }

      .column-type {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 30px;
        width: 96px;
        height: 30px;
        opacity: 0.4;
        background: #000000;
        border-radius: 6px 0px 6px 0px;
      }

      .column-name {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 22px;
      }
      // 浏览/收藏
      .column-collection {
        display: flex;
        justify-content: space-between;

        img {
          width: 14px;
          height: 14px;
          display: block;
        }

        p {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 17px;
        }
      }
    }
  }
  .task-nav {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-left {
      display: flex;
      div {
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 22px;
        position: relative;
        &:not(:first-child) {
          margin-left: 30px;
        }
      }

    p {
      position: absolute;
      left: 0;
      width: 130px;
      top: 30px;
      z-index: 99;
      display: flex;
      flex-direction: column;
      line-height: 30px;
      padding-left: 15px;
      font-size: 14px;
      max-height: 370px;
      overflow-y: scroll;
      padding-top: 15px;
      background: #F8F8F8;
      box-shadow: 0px 6px 23px 1px rgba(4, 0, 0, 0.24);
      border-radius: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-right:8px;
      }
      span {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          padding-top: 25px;
        }
      }
    }

      // .actived {
      //   font-size: 16px;
      //   font-family: PingFangSC, PingFangSC-Semibold;
      //   font-weight: 600;
      //   text-align: left;
      //   color: #000000;
      //   line-height: 22px;
      // }
    }

    .nav-right {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 20px;
      padding: 7px 20px;
      border: 1px solid #000;
      border-radius: 17px;
    }

    .nav-task {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 20px;
      padding: 7px 20px;
      border: 1px solid #000;
      border-radius: 17px;
      margin: 0 30px;
      background: #000000;
    }

    .search-input {
      width: 348px;
      height: 34px;
      border-radius: 18px;
      border: 1px solid #000;
      text-indent: 14px;
      font-size: 14px;
      outline: 0;
    }

  }
  .page-content {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
</style>
