<template>
  <div class='drama-content' @click='cancelType'>
    <div class='drama-nav'>
      <div style='display: flex;justify-content:space-between;padding-right: 30px;flex:1;'>
        <div class='nav-tab'>
          <p :class='{"tab-actived": pageType === "drama"}' @click='toBlackList("drama")'>全部剧本</p>
          <p :class='{"tab-actived": pageType === "blackList"}' @click='toBlackList("blackList")'>剧本黑马</p>
        </div>
      </div>
      <div class='search-input-box'>
        <input type="text" placeholder="请输入关键字" class="search-input" v-model="searchVal" @change='searchSort(1)'>
        <img src='https://www.jubenchaoshi.cn/img/so.png'/>
      </div>
    </div>
    <div class='nav-left'>
      <div class='actived' @click='getDrama(1)'>全部</div>
      <div @click='toChangePrice'>价格
        <span v-if='sortAsc && sortAsc === "asc"'>↑</span>
        <span v-if='sortAsc && sortAsc === "desc"'>↓</span>
      </div>
      <div>
        <span @click.stop='showType'>类型</span>
        <p v-show='isType'>
          <span v-for='(item, key) in type' :key='key' @click.stop='clickType(item.id, "type")'>
            <img src="../../assets/images/selected.png" alt="" v-if='typeSelected.includes(item.id)'>
            <img src="../../assets/images/no-selected.png" alt="" v-else>
            {{item.name}}
          </span>
        </p>
      </div>
      <div>
        <span @click.stop='showTheme'>题材</span>
        <p v-show='isTheme' class='theme-box'>
          <span v-for='(item, key) in theme' :key='key' @click.stop='clickType(item.id, "theme")'>
            <img src="../../assets/images/selected.png" alt="" v-if='themeSelected.includes(item.id)'>
            <img src="../../assets/images/no-selected.png" alt="" v-else>
            <i :title='item.name'>{{item.name}}</i>
          </span>
        </p>
      </div>
    </div>
    <div class='drama-box'>
      <div class='drama-right'>
        <div class='drama-list'>
          <div class='box-column' @click="goDetail(item.id)" v-for='(item, key) in dramaList' :key='key'>
            <img :src="item.img" alt="" class='box-img'>
            <div class='box-msg'>
              <div class='name'>{{item.title}}</div>
              <p class='msg'>作者：{{item.name}}</p>
              <p class='msg'>题材：{{item.classify}} {{item.theme}}</p>
              <p class='msg-dec '>简介：{{item.desction}}</p>
            </div>
            <img class='ewmyc' src="https://jbcsnew.oss-cn-beijing.aliyuncs.com/202304/1b98e1ccb62d691fc5d6d3b7c8cb9bff2240f0fc.svg" @mouseover="mouseOver(key)" @mouseleave="mouseLeave()" :style="active">
            <img :src="item.codeimg" alt="" class='ewm' v-show="key==curremt">
            <div class='box-star'>
              <div class='star'>市场参考价</div>
              <div class='star-num'>{{item.sprice}}</div>
            </div>
            <div class='box-collection'>
              <img v-if='item.sc' src="https://www.jubenchaoshi.cn/img/scy.png" alt="" @click.stop='toCollect(item.id, key)'>
              <img v-if='!item.sc' src="https://www.jubenchaoshi.cn/img/sc.png" alt="" @click.stop='toCollect(item.id, key)'>
            </div>
          </div>
        </div>
        <div class='page-content'>
          <div @click='toPage("index")'>首页</div>
          <div @click='toPage("prev")'>上一页</div>
          <!-- <div v-for='(item, key) in total' :key='key' @click='toPage(key)'>{{key+1}}</div> -->
          <span v-for='(item, key) in total' :key='key' @click="goUrl(key+1)" class='xhy'>
              <div v-if='key+1>actived&&key+1<actived+3'>{{key+1}}</div>
              <div v-if='key+1<actived&&key+1>actived-3'>{{key+1}}</div>
              <div v-if='key+1==actived' class='xzys'>{{key+1}}</div>
              <span v-else class='bxs'></span>
          </span>
          <div @click='toPage("next")'>下一页</div>
          <div @click='toPage("end")'>尾页</div>
          <p>当前第{{actived}}页/共{{total}}页</p>
        </div>
      </div>
      <Right-Search isDrama='1' :isBlack='pageType' @changeType='toBlackList'/>
    </div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="codeimg" alt="" class='mask-img'>
      </div>
    </div>
  </div>
</template>

<script>
import RightSearch from '../../components/RightSearch'

export default {
  components: {
    RightSearch
  },
  data () {
    return {
      dramaList: [],
      actived: 1,
      total: 0,
      type: [],
      typeSelected: [],
      searchVal: '',
      sortAsc: '',
      isType: false,
      theme: [],
      themeSelected: [],
      isTheme: false,
      pageType: 'drama',
      codeimg: '',
      isShare: false,
      curremt: 999
    }
  },
  methods: {
    //移入
    mouseOver(e) {
      console.log(e);
      this.curremt = e;
    },
    // 移出
    mouseLeave(e) {
      console.log(e);
      this.curremt = 999;
    },
    toBlackList(type) {
      console.log(type)
      if(type === 'drama') {
        this.$router.push({
          path:'/drama',
        })
      }
      this.pageType = type
      this.getDrama(1)
    },
    cancelType() {
      this.isType = false
      this.isTheme = false
    },
    toCollect(id, key) {
      this.$http.get('/collect/item', {  
        id,
        cid: 2,
      }).then((res) => {
        this.dramaList[key].sc = res.rows
        this.$toast(res.msg, 'none')
      })
    },
    showTheme() {
      this.isType = false
      if (this.isTheme) {
        this.isTheme = false
      } else {
        this.isTheme = true
      }
    },
    showType() {
      if (this.isType) {
        this.isType = false
      } else {
        this.isType = true
      }
    },
    clickType(key, type) {
      if (type === 'type') {
        if (this.typeSelected.includes(key)) {
          this.typeSelected.splice(this.typeSelected.indexOf(key), 1)
        } else {
          this.typeSelected.push(key)
        }
        this.isType = false
      } else {
        if (this.themeSelected.includes(key)) {
          this.themeSelected.splice(this.themeSelected.indexOf(key), 1)
        } else {
          this.themeSelected.push(key)
        }
        this.isTheme = false
      }
      this.searchSort(1)
    },
    toChangePrice() {
      if (!this.sortAsc) {
        this.sortAsc = 'desc'
      } else {
        this.sortAsc === 'desc' ? this.sortAsc = 'asc' : this.sortAsc = 'desc'
      }
      this.searchSort(1)
    },
    searchSort(num) {
      this.actived = num
      let params = {  
        pageSize: 10,
        pageNum: this.actived
      }
      if(this.searchVal) params.title = this.searchVal
      if(this.sortAsc){
        params.orderByColumn = 'sprice'
        params.isAsc = this.sortAsc
      }
      if(this.themeSelected.length) params.theme = this.themeSelected.join(',')
      if(this.typeSelected.length) params.classify = this.typeSelected.join(',')

      this.$http.get(`/${this.pageType}/item`, params).then((res) => {
        this.dramaList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg

      })
    },
    goUrl(id) {
      this.actived = id;
      this.searchSort(this.actived)
      window.scrollTo( 0, 0 );
    },
    goDetail(id) {

      let routedata = this.$router.resolve({
        path: '/drama/detail',
        query: {
          id,
          pageType: this.pageType
        }
      })
      window.open(routedata.href,'_blank');

      //this.$router.push({
      //  path:'/drama/detail',
      //  query: {
      //    id,
      //    pageType: this.pageType
      //  }
      //});
    },
    toPage(type) {
      switch (type) {
        case 'index':
          this.actived = 1;
          break;
        case 'prev':
          this.actived = this.actived > 1 ? this.actived-1 : 1;
          break;
        case 'next':
          this.actived = this.actived+1;
          break;
        case 'end':
          this.actived = this.total;
          break;
      }
      this.searchSort(this.actived)
      window.scrollTo( 0, 0 );
    },
    getDrama(isClear) {
      if (isClear) {
        this.actived = 1
        this.typeSelected = []
        this.themeSelected = []
        this.searchVal = ''
        this.isType = false
        this.isTheme = false
        this.sortAsc = ''
      }
      this.$http.get(`/${this.pageType}/item`, {  
        pageSize: 10,
        pageNum: this.actived
      }).then((res) => {
        this.dramaList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg

      })
    },
    // 获取类型
    getTheme() {
      this.$http.get('/tag/item/uid/1', {  
      }).then((res) => {
       this.theme = res.rows;
      })
    },
    // 获取题材
    getType() {
      this.$http.get('/tag/item/uid/0', {  
      }).then((res) => {
       this.type = res.rows;
      })
    },
  },
  created() {
    if (this.$route.query.pageType) {
      console.log(this.$route.query.pageType, 'this.$route.query.pageType')
      this.pageType = this.$route.query.pageType
    }
    this.getDrama()
    this.getType()
    this.getTheme()
    if (this.$route.query.id) {
      console.log(this.$route.query.id)
      this.typeSelected.push(Number(this.$route.query.id))
    }
    

    window.scrollTo( 0, 0 );
  },
}
</script>

<style scoped lang='less'>

.xzys{
  background:#ECBC3B;
}

.nav-left {
  width: 1200px;
  margin: 10px auto 0 auto;
  display: flex;
  div {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    color: #000000;
    line-height: 22px;
    cursor: pointer;
    position: relative;
    &:not(:first-child) {
      margin-left: 30px;
    }
  }

  p {
    position: absolute;
    left: 0;
    width: 130px;
    top: 30px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    line-height: 30px;
    padding-left: 15px;
    font-size: 14px;
    max-height: 370px;
    overflow-y: scroll;
    padding-top: 15px;
    background: #F8F8F8;
    box-shadow: 0px 6px 23px 1px rgba(4, 0, 0, 0.24);
    border-radius: 10px;
    img {
      width: 16px;
      height: 16px;
      margin-right:8px;
    }
    span {
      display: flex;
      align-items: center;
      &:not(:first-child) {
        padding-top: 25px;
      }
    }

  }
  .theme-box {
    width: 520px;
    display: flex;
    flex-wrap: wrap;
    overflow: visible;
    padding-top:0;
    span {
      width: 90px;
      padding-top: 15px!important;
      i {
        width: 66px;
        font-style:normal;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      
    }
  }
  
}
.drama-nav {
  width: 1200px;
  padding-top:60px;
  margin: 0 auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-tab {
    display: flex;
    .tab-actived {
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 18px;
      border-bottom: 2px solid #000000;
    }
    p {
      height: 28px;
      font-size: 18px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      line-height: 18px;
      margin-right: 22px;
    }
  }
  .search-input {
    width: 348px;
    height: 34px;
    border-radius: 18px;
    border: 1px solid #000;
    text-indent: 14px;
    font-size: 14px;
    outline: 0;
  }
  
  .nav-right {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #000000;
    line-height: 20px;
    padding: 7px 20px;
    border: 1px solid #000;
    border-radius: 17px;
  }

}
.drama-box {
  display: flex;
  justify-content: center;

  .drama-right {
    margin-right: 30px;
  }

  .drama-list {
    
    .box-column {
      width: 822px;
      display: flex;
      border: 1px solid #e4e4e4;
      border-radius: 7px;
      padding: 20px;
      margin-top: 24px;
      position: relative;
      background: #fff;


      .ewmyc{
        position:absolute;
        right:0px;
        top:0px;
        border-radius: 0 20px 0 0;
      }
      .ewm{
        display:block;
        width:200px;
        height:200px;
        position:absolute;
        right:50px;
        top:0px;
        z-index:9;
        border:1px solid #000;
        border-radius:10px;
        //display:none;
      }

      &:not(:first-child) {
        margin-top: 30px;
      }

      .box-img {
        width: 280px;
        height: 160px;
        display: block;
        margin-right: 20px;
      }

      .box-msg {
        flex: 1;

        .name {
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 16px;
        }

        .msg {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 14px;
          margin-top: 16px;
        }

        .msg-dec {
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 21px;
          width: 400px;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .box-star {
        position: absolute;
        top: 13px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .star-num {
          font-size: 30px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 30px;
        }

        .star {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 17px;
          margin-top: 5px;
        }
      }

      .box-collection {
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

  }

  .page-content {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
</style>
