<template>
  <div class='right-search'>
    <div class="search-upload">
      <div>
        <div class="upload-btn" @click='toUpload("https://www.jubenchaoshi.cn/htmlapi/from/join")'>入驻制作公司</div>
      </div>
      <p>把自己的公司信息上传到剧本超市的制作库，被10万名影视人看见，增加项目合作的可能，快快上传吧！</p>
    </div>

    <div class='black-list'>
      <div class='title'>剧本黑马</div>
      <div class='black-column' v-for='(item, key) in blackList' :key='key' @click='goDetail(item.id)'>
        <div class='box-img'>
          <img :src="item.img" alt="">
        </div>
        <div class='column-msg'>
          <div>{{item.title}}</div>
          <p>编剧：{{item.name}}</p>
          <p>题材：{{item.theme}}</p>
          <p>简介：{{item.desction}}</p>
        </div>
      </div>
      <div class='black-more' @click='toBlack'>查看更多</div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['isJoin'],
  data () {
    return {
      blackList: []
    }
  },
  methods: {
    toUpload(url) {
      window.open(url)
    },
    goDetail(id) {
      this.$router.push({
        path:'/drama/detail',
        query: {
          id
        }
      })
    },
    toBlack() {
      this.$router.push({
        path: '/drama',
        query: {
          pageType: 'blackList'
        }
      })
    },
    getBlackList() {
      this.$http.get('/drama/item2', {  
        pageSize: 3,
        pageNum: 1
      }).then((res) => {
        this.blackList = res.rows
      })
    }
  },
  created() {
    this.getBlackList()
  }
}
</script>

<style scoped lang='less'>
.right-search {
  width: 348px;

  .search-input {
    width: 348px;
    height: 34px;
    border-radius: 18px;
    border: 1px solid #000;
    text-indent: 14px;
    margin-top: 60px;
    font-size: 14px;
  }

  .search-upload {
    width: 348px;
    // height: 238px;
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-radius: 7px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;

    .upload-btn {
      width: 156px;
      height: 50px;
      border-radius: 26px;
      background: #000000;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload-jl {
      margin-top: 20px;
      width: 156px;
      height: 50px;
      border-radius: 26px;
      border: 1px solid #000;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      margin-top: 14px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 21px;
    }
  }

  .black-list {
    width: 348px;
    border: 1px solid #e4e4e4;
    border-radius: 7px;
    padding: 20px;
    margin-top: 30px;
    background: #fff;
    
    .title {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #000000;
      line-height: 22px;
    }

    .black-column {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      .box-img {
        width: 110px;
        height: 140px;
        display: block;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        img { 
          width: auto;
          height: auto;
          max-height: 100%;
        }
      }

      .column-msg {
        flex: 1;
        div {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 14px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 21px;
          margin-top: 6px;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

    .black-more {
      margin: 30px auto 0 auto;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      line-height: 20px;
      border: 1px solid #000000;
      border-radius: 27px;
      width: 170px;
      padding: 16px 0;
      cursor: pointer;
    }
  }
}
</style>
