<template>
  <div class='drama-hall'>
    <div class='drama-nav'>
      <div class='nav-left'>
        <div class='actived' @click='getProduction(1)'>全部</div>
      </div>
      <div style='display: flex;'>
        <div class='nav-right' @click='isShare=true'>
          设置提醒
        </div>
        <div class='search-input-box'>
          <input type="text" placeholder="请输入关键字" class="search-input" v-model="searchVal" @change='searchSort(1)'>
          <img src='https://www.jubenchaoshi.cn/img/so.png'/>
        </div> 
      </div>
      
    </div>
    <div class='drama-list'>
      <div class='box-column' v-for='(item, key) in dramaList' :key='key'>
        <video :src="item.url" controls="controls" class='box-img'></video>
        <div>{{item.title}}</div>
      </div>
      
    </div>
    <div class='page-content'>
      <div @click='toPage("index")'>首页</div>
      <div @click='toPage("prev")'>上一页</div>
      <!-- <div v-for='(item, key) in total' :key='key' @click='toPage(key)'>{{key+1}}</div> -->
      <div @click='toPage("next")'>下一页</div>
      <div @click='toPage("end")'>尾页</div>
    </div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="codeimg" alt="" class='mask-img'>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dramaList: [],
      actived: 1,
      total: 0,
      searchVal: '',
      isShare: false,
      codeimg: ''
    }
  },
  methods: {
    searchChange(num) {
      this.actived = 1;
      this.$http.get('/dramahall/item', {  
        pageSize: 10,
        pageNum: num,
        title: this.searchVal
      }).then((res) => {
        this.dramaList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg

      })
    },
    toPage(type) {
      switch (type) {
        case 'index':
          this.actived = 1;
          break;
        case 'prev':
          this.actived = this.actived > 1 ? this.actived-1 : 1;
          break;
        case 'next':
          this.actived = this.actived+1;
          break;
        case 'end':
          this.actived = this.total;
          break;
      }
      this.searchChange(this.actived)
      window.scrollTo( 0, 0 );
    },
    // goDetail(id) {
      // this.$router.push({
      //   path:'/taskHall/detail',
      //   query: {
      //     id
      //   }
      // })
    // },
    getProduction(isClear) {
      if (isClear) {
        this.searchVal = ''
      }
      this.$http.get('/dramahall/item', {  
        pageSize: 10,
        pageNum: this.actived
      }).then((res) => {
        this.dramaList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg

      })
    },
  },
  created() {
    this.getProduction()
  },

}
</script>

<style scoped lang='less'>
.drama-hall {
  width: 1210px;
  margin: 0 auto;
  .drama-list {
    display: flex;
    flex-wrap: wrap;
    .box-column {
      border-radius: 6px;
      margin-top: 30px;

      &:not(:nth-child(2n)) {
        margin-right: 30px;
      }
      .box-img {
        width: 585px;
        height: 390px;
        display: block;
      }

      div {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #000000;
        line-height: 22px;
        margin-top: 10px;
      }

      
    }
  }
  .drama-nav {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-left {
      display: flex;
      div {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 22px;
        &:not(:first-child) {
          margin-left: 30px;
        }
      }

      .actived {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 22px;
      }
    }

    .nav-right {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 20px;
      padding: 7px 20px;
      border: 1px solid #000;
      border-radius: 17px;
      margin-right: 30px;
    }

    .search-input {
      width: 348px;
      height: 34px;
      border-radius: 18px;
      border: 1px solid #000;
      text-indent: 14px;
      font-size: 14px;
      outline: 0;
    }

  }
  .page-content {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
</style>
