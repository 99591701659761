import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import axios from './untils/axios'
// import '../node_modules/swiper/swiper-bundle.css';
import Toast from './components/Toast/index'
Vue.config.productionTip = false
Vue.prototype.$http = axios


// 生成一个扩展实例构造器
const ToastConstructor = Vue.extend(Toast);

// 定义弹出组件的函数 接收三个参数 消息 toast类型 显示时间
function showToast(message, type="normal", duration = 2000) {
  const _toast = new ToastConstructor({
    data() {
      return {
        isShow: true,
        type: type,
        message: message,
        duration: duration
      }
    }
  });
  let element = _toast.$mount().$el;
  document.body.appendChild(element);
  setTimeout(() => {_toast.isShow = false} ,duration)
}

Vue.prototype.$toast = showToast

// 路由守卫
router.beforeEach((to,from,next)=>{
  console.log(to.path.indexOf('about'))
    if (to.path !== '/login' && to.path.indexOf('about') === -1 ) {
  console.log(to.path.indexOf('about'))
      axios.get('/info', {  
      }).then((res) => {
        if (res.code) {
          next({
            path:"/login"
          });
        } else {
          if (!window.localStorage.getItem('jb-userImg')) {
            window.localStorage.setItem('jb-userImg', res.data.img)
          }
          next()
        }
      })
    } else {
      next()
    }
    
});
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')


