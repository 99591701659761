<template>
  <div class='login-content'>
    <div class='header-login'>
      <img src="https://www.jubenchaoshi.cn/img/logo.png" alt="">
      <div class='headers'>
        <p class='actived' @click='toIndex'>首页</p>
        <p style="margin-right:31px;margin-left:15px;" @click='toAbout'>关于我们</p>
        <div style="margin-right:15px;"  @click='toChange("qrcode", 0)'>登录</div>
        <div @click='toChange("no", 0)'>注册</div>
      </div>
    </div>
    <div class='login-no' v-if='isStatus === "no"'>
      <h3>科技创新版权价值</h3>
      <div class="value-list">
        <div class="value-item">
          <p>版权方</p>
          <span>我是剧本、小说的作品著权人</span>
          <div class="value-btn mt150" @click='toChange("qrcode", 1)'>注册个人权利人</div>
          <div class="value-btn mt20" @click='toChange("qrcode", 2)'>注册机构权利人</div>
        </div>
        <div class="value-item ml45 mr45">
          <p>版权投资方</p>
          <span>我对剧本、小说有投资意向</span>
          <div class="value-btn mt150" @click='toChange("qrcode", 3)'>注册个人投资人</div>
          <div class="value-btn mt20" @click='toChange("qrcode", 4)'>注册机构投资人</div>
        </div>
        <div class="value-item">
          <p>版权制作方</p>
          <span>我有版权的制作能力</span>
          <div class="value-btn mt150" @click='toChange("qrcode", 5)'>注册个人制作方</div>
          <div class="value-btn mt20" @click='toChange("qrcode", 6)'>注册机构制作方</div>
        </div>
      </div>
      <h3 class="mt200">我们提供的专属服务</h3>
      <div class="serve-list">
        <div class="serve-item" v-for="(item, index) in serve" :key="index" @click='toTop'>
          <p class="title">{{item.title}}</p>
          <p class="content">{{item.desction}}</p>
        </div>
      </div>
      <h3 class="mt200">合作伙伴</h3>
      <div class="partner-list">
        <div class="partner-item" v-for="(item,key) in partner" :key="key">
          <img :src='item.img' />
        </div>
      </div>
    </div>
    <div class='login-qrcode' v-if='isStatus === "qrcode"'>
      <img :src="qrcodeImg.url" alt="" @click='refreshCode'>
      <p>扫描二维码关注公众号</p>
      <p>即可完成注册</p>
    </div>
    <!-- <div class='login-register' v-if='isStatus === "register"'>
      <img src="https://www.jubenchaoshi.cn/img/logo.png" alt="">
      <div class='register-msg'>
        <div>
          <input type="text" placeholder="请输入用户名" v-model='name'>
        </div>
        <div>
          <input type="text" v-model='phone' placeholder="请输入常用手机号">
        </div>
        <div>
          <input type="text" v-model='code' placeholder="请输入手机号验证码">
          <span @click='getPhoneCode'>获取验证码</span>
        </div>
      </div>
      <div class='register-btn' @click='getRegister'>立即注册</div>
    </div> -->
    <div class='login-login' v-if='isStatus === "phone"'>
      <img src="https://www.jubenchaoshi.cn/img/logo.png" alt="">
      <div class='login-dl'>
        <div class='login-type'>
          <p class='actived'>注册</p>
          <p @click='toChange("account")'>密码登录</p>
        </div>
        <div class='login-input'>
          <div>
            <input type="text" placeholder="请输入用户名" v-model='name'>
          </div>
          <div>
            <input type="text" placeholder="请输入手机号"  v-model='phone'>
          </div>
          <div>
            <input type="text" placeholder="请输入手机号验证码" v-model='code'>
            <span @click='getPhoneCode' v-if='!isCode'>获取验证码</span>
            <span style='color:gray;' v-if='isCode'>({{codeNum}}s)</span>
          </div>
        </div>
      </div>
      <div class='login-btn' @click='getRegister'>立即注册</div>
      <!-- <div class='login-p'>
        <p>还没有账号? <span @click='toChange("register")'>立即注册</span></p>
      </div> -->
    </div>
    <div class='login-login' v-if='isStatus === "account"'>
      <img src="https://www.jubenchaoshi.cn/img/logo.png" alt="">
      <div class='login-dl'>
        <div class='login-type'>
          <p @click='toChange("phone")'>注册</p>
          <p class='actived'>密码登录</p>
        </div>
        <div class='login-input'>
          <div>
            <input type="text" placeholder="请输入手机号" v-model='phone'>
          </div>
          <div>
            <input type="text" placeholder="请输入手机号验证码" v-model='code'>
            <span @click='getPhoneCode' v-if='!isCode'>获取验证码</span>
            <span style='color:gray;' v-if='isCode'>({{codeNum}}s)</span>
          </div>
        </div>
      </div>
      <div class='login-btn' @click='getAccount'>立即登陆</div>
      <div class='login-p'>
        <p>还没有账号? <span @click='toChange("phone")'>立即注册</span></p>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../../components/Footer'

export default {
  components: {
    Footer,
  },
  data () {
    return {
      isStatus: 'no',
      type: '',
      phone: '',
      code: '',
      name: '',
      qrcodeImg: '',
      timer: null,
      qrTimer: null,
      partner: [],
      serve: [],
      isCode: false,
      codeNum: 60,
      codeTimer: null,
      url:'',
    }
  },
  methods: {
    toIndex() {
      this.$router.push({
        path: '/index'
      })
    },
    toAbout() {
      window.location.href = window.location.origin + '/about?id=1'
    },
    toTop() {
      window.scrollTo(0,0)
    },
    toChange(key, type) {
      if (key === 'qrcode') {
        this.type = type
        this.getQrcode()
        this.getQrcodeSure()
      }
      // this.isQrcode = key
      // if (key === 'account' || key === 'register') {
      //   this.isStatus = 'qrcode'
      // } else {
      this.isStatus = key
      // }
    },
    getQrcodeSure() {
      this.timer = setInterval(() => {
        this.$http.post('/api/existsopenid', {  
          key: this.qrcodeImg.key,
        }).then((res) => {
          if (res.rows.zt === 3) {
            // this.isStatus = this.isQrcode
            clearInterval(this.timer)
            if (window.location.origin === 'https://www.jubenchaoshi.cn') {
              //////////////////////新的跳转

              this.url = this.$route.query.url;

              if(this.url){
                window.location.href = this.url;
              }else{
                window.location.href = window.location.origin + '/html'
              }

            } else {
              //////////////////////新的跳转

              this.url = this.$route.query.url;

              if(this.url){
                window.location.href = this.url;
              }else{
                window.location.href = window.location.origin + '/index'
              }

            }
          } else if (res.rows.zt === 2) {
            this.isStatus = 'phone'
            clearInterval(this.timer)
          }
        })
      }, 1000)
    },
    getRegister() {
      if (this.phone && this.code && this.name) {
        this.$http.post('/user/add', {  
          phone: this.phone,
          code: this.code,
          name: this.name,
        }).then((res) => {
          if (!res.code) {
            if (window.location.origin === 'https://www.jubenchaoshi.cn') {
              //window.location.href = window.location.origin + '/html'


              this.url = this.$route.query.url;

              if(this.url){
                window.location.href = this.url;
              }else{
                window.location.href = window.location.origin + '/html'
              }

            } else {

              this.url = this.$route.query.url;

              if(this.url){
                window.location.href = this.url;
              }else{
                window.location.href = window.location.origin + '/index'
              }

              //window.location.href = window.location.origin + '/index'
            }
          } else {
            this.$toast(res.msg)
          }
        })
      } else {
        this.$toast('请补全信息')
      }
    },
    getAccount() {
      if (this.phone && this.code) {
        this.$http.post('/user/login', {  
          phone: this.phone,
          code: this.code,
        }).then((res) => {
          if (!res.code) {
            if (window.location.origin === 'https://www.jubenchaoshi.cn') {
              //window.location.href = window.location.origin + '/html'

              this.url = this.$route.query.url;

              if(this.url){
                window.location.href = this.url;
              }else{
                window.location.href = window.location.origin + '/html'
              }

            } else {

              this.url = this.$route.query.url;

              if(this.url){
                window.location.href = this.url;
              }else{
                window.location.href = window.location.origin + '/index'
              }

              //window.location.href = window.location.origin + '/index'
            }
          } else {
            this.$toast(res.msg)
          }
        })
      } else {
        this.$toast('请补全信息')
      }
    },
    getPhoneCode() {
      if (!this.phone) return;
      this.$http.post('/user/code', {  
        phone: this.phone,
      }).then((res) => {
        if (res.code) {
          this.$toast(res.msg)
        } else {
          this.isCode = true;
          this.codeTimer = setInterval(() => {
            if (this.codeNum <=0 ) {
              clearInterval(this.codeTimer)
              this.codeNum = 60;
              this.isCode = false;
            } else {
              this.codeNum -= 1;
            }
          }, 1000)
        }
      })
    },
    refreshCode() {
      if ( this.qrcodeImg.url === 'https://www.jubenchaoshi.cn/img/gq.jpg') {
        this.getQrcode()
        if (this.timer) {
          clearInterval(this.timer)
        }
        this.getQrcodeSure()
      }
    },
    getQrcode() {
      this.$http.post('/api/img', {  
        type: this.type,
      }).then((res) => {
        this.qrcodeImg = res.rows
        if (this.qrTimer) {
          clearInterval(this.qrTimer)
        } 
        this.qrTimer = setInterval(() => {
          this.qrcodeImg.url = 'https://www.jubenchaoshi.cn/img/gq.jpg';
          clearInterval(this.qrTimer)
        }, 300000)
      })
    },
    getServe() {
      this.$http.post('/item/serve', {  
      }).then((res) => {
        this.serve = res.rows
      })
    },
    getPartner() {
      this.$http.post('/item/partner', {  
      }).then((res) => {
        this.partner = res.rows
      })
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  created() {
    console.log(window.location)
    this.getPartner()
    this.getServe()
  },
}
</script>

<style lang="less" scoped>
.login-no {
  width: 1200px;
  margin: 200px auto 0 auto;
  h3 {
    font-size: 60px;
    font-family: PingFang SC;
    font-weight: 600;
    text-align: center;
    color: #967A2F;
    background: linear-gradient(0deg, #E9CC7F 0%, #C89F4A 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.login-content {
  width: 100%;
  overflow-x: hidden ;
  height: 100%;
  background: url('https://jbcsnew.oss-cn-beijing.aliyuncs.com/202107/9506d7f33eb6946f62418560768b3c3a1055c601.png') no-repeat;
  background-size: 100% 100%;
}
.login-login {
  width: 304px;
  margin: 100px auto 160px auto;
  img {
    width: 200px;
    height: 56px;
    display: block;
    margin: 0 auto;
  }
  

  .login-dl {
    .login-type {
      margin: 24px 0 30px 0;
      display: flex;
      padding: 0 30px;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #999;
      line-height: 22px;
      .actived {
        color: #fff;
      }
    }
    .login-input {
      div {
        width: 304px;
        height: 49px;
        margin-bottom: 20px;
        position: relative;
        background: #fff;
        border-radius: 6px;

        input {
          width: 100%;
          height: 100%;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          border: 1px solid #ddd;
          outline: 0;
          line-height: 20px;
          border-radius: 6px;
          padding: 14px 20px;
        }

        span{
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          line-height: 20px;
          position: absolute;
          right: 20px;
          top: 14px;
          color: #E02020;
        }
      }
    }
  }

  .login-btn {
    margin: 10px auto 20px auto;
    width: 170px;
    height: 52px;
    background: #e8cb7e;
    border-radius: 26px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
  }

  .login-p {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    span {
      color: #E02020;
    }
  }

}
// 注册
.login-register {
  width: 540px;
  margin: 100px auto 0 auto;
  img {
    // width: 200px;
    // height: 56px;
    display: block;
    margin-bottom: 6px;
    margin: 0 auto;
  }
  .register-btn{
    margin: 30px auto 20px auto;
    width: 170px;
    height: 52px;
    background: #e8cb7e;
    border-radius: 26px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .register-rule{
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 14px;
      height: 14px;
      border: 1px solid #d57d7d;
      background: #fff;
      margin-right: 10px;
    }
    .checked {
      background: #d57d7d;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 20px;
    }
  }
  .register-msg {
    div {
      width: 540px;
      height: 49px;
      margin-top: 20px;
      position: relative;
      background: #fff;
      border-radius: 6px;

      input {
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        border: 1px solid #ddd;
        outline: 0;
        line-height: 20px;
        border-radius: 6px;
        padding: 14px 20px;
      }

      span{
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #e02020;
        line-height: 20px;
        position: absolute;
        right: 20px;
        top: 14px;
      }
    }
  }
  

}
.header-login {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  height: 60px;
  align-items: center;
  margin: 0 auto;
  
  img {
    width: 129px;
    height: 36px;
  }

  .headers {
    display: flex;
    align-items: center;
    .actived {
      background: #422E7E;
      opacity: 0.6;
    }
    p {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 60px;
      height: 60px;
      padding: 0 16px;
      cursor: pointer;
    }
    div {
      cursor: pointer;
      font-size: 14px;
      height: 30px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #E9CC7F;
      line-height: 14px;
      padding: 8px 17px;
      border: 1px solid #E9CC7F;
    }
  }
}
.login-qrcode {
  margin: 100px auto 0 auto;
  width: 325px;
  img {
    width: 325px;
    height: 325px;
    display: block;
  }
  p {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    margin-top:9px;
    text-align: center;
  }
}


// 科技创新版权价值
.ml45 {
  margin-left: 45px;
}
.mr45 {
  margin-right: 45px
}
.mt150 {
  margin-top: 150px;
}
.mt20 {
  margin-top: 20px;
}
.mt200 {
  margin-top: 200px;
}
.value-list {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  .value-item {
    width: 366px;
    height: 382px;
    background: rgba(126,99,201,0.3);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 30px;
      font-family: PingFang SC;
      margin-top: 50px;
      font-weight: 600;
      color: #FFFFFF;
    }
    span {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      margin-top: 9px;
    }
    .value-btn {
      width: 158px;
      height: 30px;
      background: linear-gradient(0deg, #E9CC7F, #CFA955);
      border-radius: 15px;
      line-height: 30px;
      text-align:center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;

      &:hover{
        box-shadow: 0px 0px 10px #CFA955; 
        // background: #f5f5f5;
        transform: translateY(-10px);
      }
    }
  }
}
// 专属服务
.serve-list {
  width: 1230px;
  height: auto;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  .serve-item {
    width: 380px;
    height: 190px;
    margin-top: 30px;
    margin-right: 30px;
    background: rgba(126,99,201, 0.3);
    border-radius: 10px;
    padding: 30px 35px 40px 30px;
    position: relative;
    &:hover{
      opacity: 0.8;
      transform: translateY(-10px);
    }
    .number {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 87px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #080317;
      opacity: 0.7;
    }
    .title {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
    .content {
      margin-top: 13px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      position: relative;
    }
  }
}

// 合作伙伴
.partner-list {
  width: 1224px;
  height: auto;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  .partner-item {
    width: 106px;
    height: 106px;
    border-radius: 50%;
    margin-right: 30px;
    margin-top: 30px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
