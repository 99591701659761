<template>
  <div class='header-component' :class='{"header-put" : isHeaderPut, "header-about": isAbout}' :style='{backgroundImage: `url(${headerBanner})`, backgroundSize: "100% 100%"}'>
    <div class='header-nav'>
      <div class='nav-left'>
        <img src="https://www.jubenchaoshi.cn/img/logo.png" alt="" class='nav-logo' @click='toLink("/index")'>
        <div>
          <p :class="{'nav-actived': activedInd=== item.path}" v-for='(item, key) in navList' :key='key' @click='toLink(item.path)'>{{item.name}}</p>
        </div>
      </div>
      <div class='nav-right'>
        <div @click='toUpload("https://www.jubenchaoshi.cn/htmlapi/from/resume")'>简历上传</div>
        <div @click='toUpload("https://www.jubenchaoshi.cn/htmlapi/from/contribute")'>剧本上传</div>
        <div @click='toUpload("https://www.jubenchaoshi.cn/htmlapi/from/task")'>任务上传</div>
        <img src="https://www.jubenchaoshi.cn/img/so.png" alt="" class='nav-search' @click='toPath("/search")'>
        <p class='nav-line'></p>
        <img :src="userImg" alt="" class='nav-user' @click="goCenter">
      </div>
    </div>
    <!-- <div class='header-title' v-if='!isHeaderPut'>剧本超市综合服务平台</div> -->
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: ['isHeaderPut', 'headerBanner', 'isAbout'],
  data () {
    return {
      activedInd: '/index',
      navList: [{
        name: '首页',
        path: '/index'
      }, {
        name: '编剧库',
        path: '/scenario'
      }, {
        name: '剧本库',
        path: '/drama'
      }, {
        name: '制作库',
        path: '/production'
      }, {
        name: '任务大厅',
        path: '/taskHall'
      }, {
        name: '剧本堂',
        path: '/scriptHall'
      }, ],
      userImg: ''
    }
  },
  created() {
    let { path } = this.$route
    if (this.navList.some((val) => val.path === path)) {
      this.activedInd = path
    }
    this.userImg = window.localStorage.getItem('jb-userImg')
  },
  methods: {
    toUpload(url) {
      window.open(url)
    },
    toPath(url) {
      this.$router.push(url)
    },
    toLink(url) {
      this.activedInd = url
      this.$router.push(url)
    },
    goCenter() {
      this.$router.push('/userCenter')
    }
  },
}
</script>

<style scoped lang='less'>
.header-component {
  height: 580px;
  width: 100%;
  // background: url('https://www.jubenchaoshi.cn/img/banner.jpg');
  background-size: 100% 100%;
}

.header-put {
  height: 300px;
  background-size: 100% 100%;
}
.header-about{
  height: 60px;
}
.header-nav {
  display: flex;
  justify-content: space-between;
  // padding:0 300px;
  width: 1200px;
  margin: 0 auto;

  .nav-left {
    display: flex;
    height: 60px;

    .nav-logo {
      width: 129px;
      height: 36px;
      margin-top: 15px;
    }

    div {
      display: flex;

      p {
        padding-top: 19px;
        margin-left: 20px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        line-height: 22px;
      }

      .nav-actived {
        border-bottom: 1px solid #fff;
      }
    }
  }

  .nav-right {
    display: flex;
    height: 60px;
    // padding-top: 14px;
    align-items: center;

    div {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #ffffff;
      line-height: 22px;
      margin-right: 20px;
    }

    .nav-search {
      width: 25px;
      height: 25px;
    }

    .nav-line {
      height: 20px;
      width: 1px;
      background: #fff;
      margin: 0 20px;
    }

    .nav-user {
      width: 32px;
      height: 32px;
      background: #fff;
      border-radius: 50%;
    }

  }
}

.header-title {
  font-size: 99px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 139px;
  margin-top: 167px;
}
</style>
