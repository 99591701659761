<template>
	<div class='detail-box'>
		<div class="detail-left">
			<div class="title-name">
				<p>{{detailData.title}}</p>
        <img class="share-icon" src="../../assets/images/share.png" alt="" @click='isShare=true' style='cursor:pointer'>
        <!-- <div class='share-content' v-if='isShare'>
          <span @click='isShare=false'>x</span>
          <img :src="detailData.codeimg" alt="">
        </div> -->
			</div>
      <div class="user-info">
        <div class="flex">
          <div class="user-img"></div>
          <p class="user-name">{{detailData.name}}</p>
        </div>
        <p class="people">浏览人数：{{detailData.hot}}</p>
      </div>
      <div class="table-info">
        <div class="cell">
          <div class="cell-left">任务名称</div>
          <div class="cell-right">{{detailData.title}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">任务类型</div>
          <div class="cell-right">{{detailData.lx}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">任务报酬</div>
          <div class="cell-right">{{detailData.price}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">截止日期</div>
          <div class="cell-right">{{detailData.itemtime}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">发布方</div>
          <div class="cell-right">{{detailData.name}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">任务标签</div>
          <div class="cell-right">{{detailData.classify}}{{detailData.theme}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">任务要求</div>
          <div class="cell-right">{{detailData.demand}}</div>
        </div>
      </div>

      <p class="title-tip">任务详情</p> 
      <p class="new-img"  v-html='detailData.body'></p>
		</div>
		<div class="detail-right">
      <div class="right-status">
        <p>正在进行中</p>
        <div @click='toTaskAdd' v-if='detailData.bm === 0'>立即报名</div>
        <div v-if='detailData.bm === 1'>已报名</div>
      </div>
      <div class='right-flow'>
        <div class='flow-title'>报名流程</div>
        <div class='flow-box flow-box-actived'>
          <div>1</div>
          <p>立即报名</p>
        </div>
        <div class='flow-box'>
          <div>2</div>
          <p>等待筛选</p>
        </div>
        <div class='flow-box'>
          <div>3</div>
          <p>报名成功</p>
        </div>
        <div class='flow-box'>
          <div>4</div>
          <p>推送洽谈室</p>
        </div>
        <div class='flow-box'>
          <div>5</div>
          <p>登陆洽谈室</p>
        </div>
        <div class='flow-box'>
          <div>6</div>
          <p>签保密协议</p>
        </div>
        <div class='flow-box'>
          <div>7</div>
          <p>开始会议</p>
        </div>
        <div class='flow-box'>
          <div>8</div>
          <p>商定线下洽谈</p>
        </div>
      </div>
      <div class='right-task'>
        <div class='flow-title'>任务大厅</div>
        <div class='box-column' v-for='(item, key) in detailHot' :key='key'>
          <img :src="item.img" alt="" class='box-img'>
          <div class='column-msg'>
            <div class='column-type'>版权信息</div>
            <div class='column-name'>{{item.title}}</div>
            <div class='column-collection'>
              <p>浏览人数: {{item.hot}}</p>
              <!-- <img src="" alt=""> -->
            </div>
          </div>
          <div class='box-bottom'>
            <div>
              <p  class='price'>{{item.price}}</p>
              <span>成交价格</span>
            </div>
            <div>
              <p class='price'>{{item.itemtime}}</p>
              <span>结束时间</span>
            </div>
          </div>
        </div>
        <div class='black-more' @click='toBlack'>查看更多</div>
      </div>
    </div>

    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="detailData.codeimg" alt="" class='mask-img'>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  data() {
    return {
      detailData: {},
      detailHot: [],
      isShare: false,
    }
  },
  methods: {
    toShare() {
      window.open('https://www.jubenchaoshi.cn/img/jbcs.jpg')
    },
    toTaskAdd() {
      this.$http.post('/taskhall/add', {
        id: this.$route.query.id
      }).then((res) => {
        this.$toast(res.msg, 'none')
      })
    },
    getDetail(id) {
      this.$http.get('/taskhall/itemone', {  
        id
      }).then((res) => {
        this.detailData = res.rows
      })
    },
    getHot(id) {
      this.$http.get('/taskhall/righthot', {  
        id
      }).then((res) => {
        this.detailHot = res.rows
      })
    },
    toBlack() {
      this.$router.push({
        path: '/taskhall'
      })
    },
  },
  created() {
    this.getHot()
    this.getDetail(this.$route.query.id)
  },
}
</script>

<style scoped lang='less'>
.flex {
  display: flex;
  align-items: center;
}
.detail-box {
	display: flex;
  justify-content: center;
  background: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 60px;
}
.detail-left {
	width: 822px;
	background: #ffffff;
	border-radius: 6px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	.title-name {
		display: flex;
		align-content: center;
		justify-content: space-between;
		p {
			font-size: 18px;
			font-family: PingFangSC, PingFangSC-Semibold;
			font-weight: 600;
			text-align: left;
			color: #000000;
    }
    .share-icon {
      width: 17px;
      height: 17px;
    }
    .share-content {
      position: absolute;
      right: 0px;
      top: -245px;
      width: 215px;
      height: 245px;
      background: #fff;
      padding: 10px 20px 20px 20px;
      border-radius: 10px;
      span {
        font-size: 20px;
        color: #000;
        text-align: right;
        padding-right: 10px;
        display: block;
      }
      img {
        display: block;
        width: 175px;
        height: 185px;
      }
    }
  }
  .user-info {
    display: flex;
		align-content: center;
    justify-content: space-between;
    margin-top: 10px;
    .user-img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    .user-name {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      margin-left: 5px;
    }
    .people {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
    }
  }
  .table-info {
    width: 100%;
    height: auto;
    border: 1px solid #e4e4e4;
    border-bottom: none;
    margin-top: 11px;
    .cell {
      display: flex;
      width: 100%;
      height: 54px;
      border-bottom: 1px solid #e4e4e4;
      .cell-left {
        width: 96px;
        height: 100%;
        border-right: 1px solid #e4e4e4;
        display: flex;
        align-items: center;
        text-indent: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000000;
      }
      .cell-right {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        text-indent: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000000;
      }
    } 
  }
  .title-tip {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
    margin-top: 30px;
  }
  .title-desc {
    margin-top: 6px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #000000;
    line-height: 21px;
  }
}
.detail-right {
	width: 348px;
	// height: 100px;
  margin-left: 30px;
  .right-status {
    width: 348px;
    height:152px;
    background: #ffffff;
    border-radius: 6px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 30px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #e02020;
      line-height: 42px;
    }

    div {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 20px;
      padding: 15px 50px;
      background: #000000;
      border-radius: 24px;
      width: 156px;
      margin-top: 20px;
    }
  }

  .right-flow{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 6px;
    margin-top: 30px;

    .flow-title {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 16px;
    }

    .flow-box {
      display: flex;
      margin-top: 20px;
      width: 165px;
      div {
        width: 17px;
        height: 17px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 16px;
        border: 1px solid #000;
      }
      
      p {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 14px;
        margin-left: 10px;
      }
    }

    .flow-box-actived {
      div {
        background: #000000;
        color: #fff;
      }
    }
  }

  .right-task {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 6px;
    margin-top: 30px;

    .flow-title {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 16px;
    }

    .box-column {
      border-radius: 6px;
      border: 1px solid #ddd;
      position: relative;
      margin-top: 20px;

      .box-img {
        width: 278px;
        height: 200px;
        display: block;
      }

      .box-bottom {
        display: flex;
        border-top: 1px solid #ddd;
        div {
          padding: 10px 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #e02020;
          line-height: 30px;

          &:not(:first-child) {
            border-left: 1px solid #ddd;
          }

          span {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 17px;
          }

          .price {
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: center;
            color: #e02020;
            line-height: 22px;
          }
        }
      }

      .column-msg {
        padding: 5px 10px 10px 10px;
      }

      .column-type {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 30px;
        width: 96px;
        height: 30px;
        opacity: 0.4;
        background: #000000;
        border-radius: 6px 0px 6px 0px;
      }

      .column-name {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 22px;
      }
      // 浏览/收藏
      .column-collection {
        display: flex;
        justify-content: space-between;

        img {
          width: 14px;
          height: 14px;
          display: block;
        }

        p {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 17px;
        }
      }
    }
  }
  .black-more {
      margin: 30px auto 0 auto;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      line-height: 20px;
      border: 1px solid #000000;
      border-radius: 27px;
      width: 170px;
      padding: 16px 0;
      cursor: pointer;
    }
}
.new-img{
	margin-top: 20px;
  /deep/ img {
    width: 782px;
    display: inline-block;
  }
}
</style>