<template>
	<div class="detail-box">
		<div class="detail-left">
			<div class="top-info">
				<div class="title-container">
					<p class="name">{{detailData.title}}</p>
					<div class="right-btn">
            <img class="btn" src="https://www.jubenchaoshi.cn/img/jba1.png" alt="">
            <img class="btn" src="https://www.jubenchaoshi.cn/img/jba2.png" alt="">
            <img class="btn" src="https://www.jubenchaoshi.cn/img/jba3.png" alt=""  @click='isShare=true' style='cursor:pointer'>
            <img class="btn" src="https://www.jubenchaoshi.cn/img/jba4.png" alt="">
            <!-- <div class='share-content' v-if='isShare'>
              <span @click='isShare=false'>x</span>
              <img :src="detailData.codeimg" alt="">
            </div> -->
					</div>
				</div>

				<div class="info-container">
					<div class="left-img">
						<div class="banner-img">
              <img :src="showImg" alt="">
            </div>
						<div class="swiper-img">
							<div class="img-item" v-for='(item, key) in detailData.imgimg' :key='key' @click='showImg = item.img'>
                <img :src="item.img" alt="">
              </div>
						</div>
					</div>
					<div class="right-info">
						<p class="right-desc">编剧：{{detailData.name}}</p>
						<p class="right-desc">评估价：{{detailData.pprice}}</p>
						<p class="right-desc">市场价：{{detailData.sprice}}</p>
						<p class="right-desc">版权状态：{{detailData.bqzt ? '部分版权' : '全版权'}}</p>
						<p class="right-desc">关注该项目人数：{{detailData.hot}}</p>
						<div class="btn-view"  @mouseover="mouseOver(1)" @mouseleave="mouseLeave()">
							<div class="btn-first">
								<p class="tip">预订剧本</p>
								<p class="desc">（订金百分之一）</p>
							</div>
							<div class="btn ml20">预览剧本</div>
							<div class="btn ml20">联系版权经理</div>
						</div>
						<img :src="detailData.codeimg" alt="" class='ewm' v-show="1==curremt">
					</div>
				</div>

				<div class="promise-list">
					<div class="promise-item">
						<p>七天无理由退款</p>
						<div class="icon" title='该剧本支持甲方客户在7天内无条件退回版权产品，平台交易的服务费不予退还。'>?</div>
					</div>
					<div class="promise-item">
						<p>可分期</p>
						<div class="icon" title='是指购买剧本版权后，可以分期付款，付款的周期一般为签订合约定金支付20%，备案立项，拿到拍摄许可证后支付30%，发布筹备公告支付20%，开机支付尾款30%。如未按约定时间支付全款，版权自动回到版权方，已支付金额不予退还。'>?</div>
					</div>
					<div class="promise-item">
						<p>版权保险</p>
						<div class="icon" title='只是在平台交易进行的版权交易，甲乙双方可为版权进行保险，保单由太平洋保险公司承保。防止版权的侵权、抄袭等状况，保险公司支付律师费、调查费、诉讼费等。'>?</div>
					</div>
					<div class="promise-item">
						<p>版权拍卖</p>
						<div class="icon" title='可为版权进行竞价拍卖，目前剧本超市联合阿里拍卖、文交所、版权交易中心、拍卖行等进行版权的竞价拍卖。点击进入会跳转到第三方平台进行拍卖业务。'>?</div>
					</div>
				</div>
			</div>

			<div class="top-info mt30">
				<div class="table-info">
					<div class="cell">
						<div class="cell-left">版权介质</div>
						<div class="cell-right">{{detailData.classify}} {{detailData.theme}}</div>
					</div>
					<div class="cell">
						<div class="cell-left">版权证书</div>
						<div class="cell-right" v-if='!detailData.bqzh'>暂无</div>
						<div class="cell-right check" v-if='detailData.bqzh' @click='toCheck(detailData.bqzimg)'>查看</div>
						<!-- <div class="cell-right">{{detailData.bqzh}}</div> -->
					</div>
					<div class="cell">
						<div class="cell-left">可授权类型</div>
						<div class="cell-right">{{detailData.banquan}}</div>
					</div>
					<div class="cell">
						<div class="cell-left">版权评估报告</div>
						<div class="cell-right" v-if='!detailData.pgzt'>暂无</div>
						<div class="cell-right check" v-if='detailData.pgzt' @click='toCheck(detailData.bqpg)'>查看</div>
					</div>
				</div>
			<!-- <div class='banner-tab'>
			<p :class='{"actived": isType === "详细"}' @click='changeType("详细")'>详细</p>
			<p :class='{"actived": isType === "留言"}' @click='changeType("留言")'>留言</p>
			</div> -->
			<div v-if='pageType === "blackList"'>
				<div class='banner-tab'>
					<p :class='{"actived": isType === "详细"}' @click='changeType("详细")'>详细</p>
					<p :class='{"actived": isType === "留言"}' @click='changeType("留言")'>评论</p>
				</div> 
				<div class="banner" v-html='detailData.body' v-if='isType === "详细"'>
				
				</div>
				<div class='lyb-content' v-if="isType === '留言'">
					<div v-for='(item, key) in lyb' :key='key'>
					<div class='msg'>{{item.body}}</div>
					</div>
				</div>
			</div>
			<div  v-if='pageType === "drama"'>
			<div class="banner" v-html='detailData.body'>
			
			</div>
			</div>
				
			</div>
			
		</div>
		<div class="detail-right">
			<right-Section :blackList='detailData' :pageType='pageType'/>
		</div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="detailData.codeimg" alt="" class='mask-img'>
      </div>
    </div>
	</div>
</template>

<script>
import rightSection from '../../components/RightSearch/right-drama'
export default {
  components: {
    rightSection
  },
  data () {
    return {
		curremt: 999,
		isType: '详细',
		detailData: {},
		pageType: 'drama',
		showImg: '',
		isShare: false,
		lyb: [],
    }
  },
  methods: {
	//移入
    mouseOver(e) {
      console.log(e);
      this.curremt = e;
    },
    // 移出
    mouseLeave(e) {
      console.log(e);
      this.curremt = 999;
    },
	toShare() {
      window.open('https://www.jubenchaoshi.cn/img/jbcs.jpg')
    },
    toCheck(url) {
      window.open(url)
    },
    changeType(key) {
      this.getWork()
      this.isType = key
    },
    getDetail(id) {
      this.$http.get(`/${this.pageType}/itemone`, {  
        id
      }).then((res) => {
        this.detailData = res.rows
        this.showImg = res.rows.imgimg[0].img
      })
    },
    getWork() {
      this.$http.post(`/blackList/ly`, {  
        id: this.$route.query.id
      }).then((res) => {
        this.lyb = res.rows
      })
    }
  },
  created() {
    console.log(this.$route.query)
    if (this.$route.query.pageType) {
      this.pageType = this.$route.query.pageType
    }
    this.getDetail(this.$route.query.id)
  },
}
</script>

<style scoped lang='less'>
.ml20 {
	margin-left: 20px;
}
.mt30 {
	margin-top: 30px;
}
.table-info {
	width: 100%;
	height: auto;
	border: 1px solid #e4e4e4;
	border-bottom: none;
	.cell {
		display: flex;
		width: 100%;
		height: 54px;
		border-bottom: 1px solid #e4e4e4;
		.cell-left {
			width: 124px;
			height: 100%;
			border-right: 1px solid #e4e4e4;
			display: flex;
			align-items: center;
			text-indent: 20px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #999999;
    }

		.cell-right {
			flex: 1;
			height: 100%;
			display: flex;
			align-items: center;
			text-indent: 20px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #000000;
    }
    .check {
      text-decoration: underline;
      color: skyblue;
      cursor: pointer;
    }
	}
}
.detail-box {
	display: flex;
  justify-content: center;
  background: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 60px;
}
.detail-left {
	width: 822px;
	display: flex;
	flex-direction: column;
	.top-info {
		width: 822px;
		background: #ffffff;
		border-radius: 6px;
		padding: 20px;
		.title-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 30px;
			.name {
				font-size: 18px;
				font-family: PingFangSC, PingFangSC-Semibold;
				font-weight: 600;
				text-align: left;
				color: #000000;
			}
			.right-btn {
        display: flex;
        position: relative;
        .share-content {
          position: absolute;
          right: 0px;
          top: -245px;
          width: 215px;
          height: 245px;
          background: #fff;
          padding: 10px 20px 20px 20px;
          border-radius: 10px;
          span {
            font-size: 20px;
            color: #000;
            text-align: right;
            padding-right: 10px;
            display: block;
          }
          img {
            display: block;
            width: 175px;
            height: 185px;
          }
        }
				.btn {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					// background: #d57d7d;
					// line-height: 30px;
					// font-size: 8px;
					// font-family: PingFangSC, PingFangSC-Regular;
					// font-weight: 400;
					// text-align: center;
					// color: #000000;
					margin-left: 13px;
				}
			}
		}

		.info-container {
			display: flex;
			margin-top: 5px;
			.left-img {
				width: 360px;
				margin-right: 20px;
				.banner-img {
          img {
            width: 360px;
            height: 202px;
          }
				}
				.swiper-img {
					display: flex;
					width: 100%;
					overflow: hidden;
					margin-right: -10.6px;
					margin-top: 10px;
					.img-item {
						margin-right: 10.6px;
            img {
              cursor: pointer;
              width: 82px;
              height: 46px;
            }
					}
				}
			}
			.right-info {
				position: relative;
				flex-grow: 1;
				width: 0;
				.ewm{
					display:block;
					width:200px;
					height:200px;
					position:absolute;
					right:50px;
					top:0px;
					z-index:9;
					border:1px solid #000;
					border-radius:10px;
					//display:none;
				}
				.right-desc {
					font-size: 14px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					text-align: left;
					color: #000000;
					margin-top: 20px;
					line-height:1;
					&:nth-child(1) {
						margin-top: 0px;
					}
				}
				.btn-view {
					display: flex;
					margin-top: 56px;
					.btn-first {
						width: 118px;
						height: 52px;
						background: #000000;
						border-radius: 6px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						.tip {
							font-size: 14px;
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							color: #ffffff;
						}
						.desc {
							font-size: 8px;
							font-family: PingFangSC, PingFangSC-Regular;
							font-weight: 400;
							margin-top: 2px;
							color: #d63d3d;
						}
					}
					.btn {
						width: 118px;
						height: 52px;
						border: 1px solid #000000;
						border-radius: 6px;
						line-height: 52px;
						text-align: center;
						font-size: 14px;
						font-family: PingFangSC, PingFangSC-Regular;
						font-weight: 400;
						color: #000000;
					}
				}
			}
		}

		.promise-list {
			display: flex;
			margin-top: 30px;
			.promise-item {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 30px;
				p {
					font-size: 16px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					text-align: left;
					color: #c54040;
				}
				.icon {
					width: 12px;
					height: 12px;
					opacity: 1;
					border: 1px solid #000000;
					border-radius: 50%;
					margin-left: 5px;
					font-size: 12px;
					text-align: center;
					line-height: 12px;
				}
			}
		}
		
	}
	
}
.detail-right {
	width: 348px;
  margin-left: 30px;
}
.banner{
	margin-top: 20px;
  /deep/ img {
	width: 782px;
    display: inline-block;
	
  }
}
.banner-tab {
  display: flex;
  align-items: center;
  margin-top: 20px;
  p {
    font-size:18px;
    margin-right:20px;
  }
  .actived {
    font-weight: bold;
    font-size:18px;
  }
}
.lyb-content{
  .msg {
    width: 100%;
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-top: 10px;
  }
}
</style>