<template>
  <div class='scenario-content' @click='cancelType'>
    <div class='scenario-nav'>
      <div style='display: flex;justify-content:space-between;padding-right: 30px;flex:1;'>
        <div class='nav-left'>
          <div class='actived' @click='getScenario(1)'>全部</div>
          <div @click='toChangePrice'>评分
            <span v-if='sortAsc && sortAsc === "asc"'>↑</span>
            <span v-if='sortAsc && sortAsc === "desc"'>↓</span>
          </div>
          <div>
            <span @click.stop='showType'>类型</span>
            <p v-show='isType'>
              <span v-for='(item, key) in type' :key='key' @click.stop='clickType(item.id, "type")'>
                <img src="../../assets/images/selected.png" alt="" v-if='typeSelected.includes(item.id)'>
                <img src="../../assets/images/no-selected.png" alt="" v-else>
                {{item.name}}
              </span>
            </p>
          </div>
          <div>
            <span @click.stop='showTheme'>题材</span>
            <p v-show='isTheme' class='theme-box'>
              <span v-for='(item, key) in theme' :key='key' @click.stop='clickType(item.id, "theme")'>
                <img src="../../assets/images/selected.png" alt="" v-if='themeSelected.includes(item.id)'>
                <img src="../../assets/images/no-selected.png" alt="" v-else>
                <i :title='item.name'>{{item.name}}</i>
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class='search-input-box'>
        <input type="text" placeholder="请输入关键字" class="search-input" v-model="searchVal" @change='searchSort(1)'>
        <img src='https://www.jubenchaoshi.cn/img/so.png'/>
      </div> 

    </div>
    <div class='scenario-box'>
      <div class='scenario-right'>
        <div class='scenario-list'>
          <div class='box-column' @click="goEdit(item.id)" v-for='(item, key) in scenarioList' :key='key'>
            <div class='box-img'>
              <img :src="item.img" alt="" >
            </div>
            <div class='box-msg'>
              <div class='name'>{{item.title}}</div>
              <p class='msg'>所在地:{{item.city}}</p>
              <p class='msg'>毕业院校：{{item.school}}</p>
              <p class='msg'>擅长题材：{{item.theme}}</p>
              <p class='msg msg-one'>过往作品：{{item.old}}</p>
              <p class='msg msg-line'>简介：{{item.desction}}</p>
              <img class='ewmyc' src="https://jbcsnew.oss-cn-beijing.aliyuncs.com/202304/1b98e1ccb62d691fc5d6d3b7c8cb9bff2240f0fc.svg" @mouseover="mouseOver(key)" @mouseleave="mouseLeave()" :style="active">
              <img :src="item.codeimg" alt="" class='ewm' v-show="key==curremt">
            </div>
            <div class='box-star'>
              <div class='star-num'>{{item.hot}}</div>
              <div class='star'>
                <img src="https://www.jubenchaoshi.cn/img/wxy.png" alt="" v-for='(item, key) in Math.floor(item.hot/2)' :key='key'>
                <img src="https://www.jubenchaoshi.cn/img/wxb.png" alt="" v-for='(item, key) in Math.ceil(item.hot/2) - Math.floor(item.hot/2)' :key='key'>
              </div>
            </div>
            <div class='box-collection'>
              <img v-if='item.sc' src="https://www.jubenchaoshi.cn/img/scy.png" alt="" @click.stop='toCollect(item.id, key)'>
              <img v-if='!item.sc' src="https://www.jubenchaoshi.cn/img/sc.png" alt="" @click.stop='toCollect(item.id, key)'>
            </div>
          </div>
          <div class='page-content'>
            <div @click='toPage("index")'>首页</div>
            <div @click='toPage("prev")'>上一页</div>
            <span v-for='(item, key) in total' :key='key' @click="goUrl(key+1)" class='xhy'>
              <div v-if='key+1>actived&&key+1<actived+3'>{{key+1}}</div>
              <div v-if='key+1<actived&&key+1>actived-3'>{{key+1}}</div>
              <div v-if='key+1==actived' class='xzys'>{{key+1}}</div>
              <span v-else class='bxs'></span>
            </span>
            <div @click='toPage("next")'>下一页</div>
            <div @click='toPage("end")'>尾页</div>
            <p>当前第{{actived}}页/共{{total}}页</p>
          </div>
        </div>
      </div>
      <Right-Search />
    </div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="codeimg" alt="" class='mask-img'>
      </div>
    </div>
  </div>
</template>

<script>
import RightSearch from '../../components/RightSearch'

export default {
  components: {
    RightSearch
  },
  data () {
    return {
      isModal: false,
      scenarioList: [],
      scenarioDetail: {},
      scenarioId: '',
      actived: 1,
      total: 0,
      type: [],
      typeSelected: [],
      theme: [],
      themeSelected: [],
      searchVal: '',
      isType: false,
      isTheme: false,
      sortAsc: '',
      codeimg: '',
      isShare: false,
      curremt:999
    }
  },
  methods: {
    cancelType() {
      this.isType = false
      this.isTheme = false
    },
    //移入
    mouseOver(e) {
      console.log(e);
      this.curremt = e;
    },
    // 移出
    mouseLeave(e) {
      console.log(e);
      this.curremt = 999;
    },
    toCollect(id, key) {
      this.$http.get('/collect/item', {  
        id,
        cid: 1,
      }).then((res) => {
        this.scenarioList[key].sc = res.rows
        this.$toast(res.msg, 'none')
      })
    },
    toChangePrice() {
      if (!this.sortAsc) {
        this.sortAsc = 'desc'
      } else {
        this.sortAsc === 'desc' ? this.sortAsc = 'asc' : this.sortAsc = 'desc'
      }
      this.searchSort(1)
    },
    showType() {
      this.isTheme = false
      if (this.isType) {
        this.isType = false
      } else {
        this.isType = true
      }
    },
    showTheme() {
      this.isType = false
      if (this.isTheme) {
        this.isTheme = false
      } else {
        this.isTheme = true
      }
    },
    clickType(key, type) {
      if (type === 'type') {
        if (this.typeSelected.includes(key)) {
          this.typeSelected.splice(this.typeSelected.indexOf(key), 1)
        } else {
          this.typeSelected.push(key)
        }
        this.isType = false
      } else {
        if (this.themeSelected.includes(key)) {
          this.themeSelected.splice(this.themeSelected.indexOf(key), 1)
        } else {
          this.themeSelected.push(key)
        }
        this.isTheme = false
      }
      this.searchSort(1)
    },
    searchSort(num) {
      this.actived = num
      let params = {  
        pageSize: 10,
        pageNum: this.actived
      }
      if(this.searchVal) params.title = this.searchVal
      if(this.themeSelected) params.theme = this.themeSelected.join(',')
      if(this.typeSelected) params.classify = this.typeSelected.join(',')
      if(this.sortAsc){
        params.orderByColumn = 'hot'
        params.isAsc = this.sortAsc
      }
      this.$http.get('/scenario/item', params).then((res) => {
        this.scenarioList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg
      })
    },
    // 获取题材
    getType() {
      this.$http.get('/tag/item/uid/1', {  
      }).then((res) => {
       this.theme = res.rows;
      })
    },
    // 获取类型
    getTheme() {
      this.$http.get('/tag/item/uid/0', {  
      }).then((res) => {
       this.type = res.rows;
      })
    },
    toPage(type) {
      switch (type) {
        case 'index':
          this.actived = 1;
          break;
        case 'prev':
          this.actived = this.actived > 1 ? this.actived-1 : 1;
          break;
        case 'next':
          this.actived = this.actived+1;
          break;
        case 'end':
          this.actived = this.total;
          break;
      }
      this.searchSort(this.actived)
      window.scrollTo( 0, 0 );
    },
    toClose() {
      this.isModal = false
      this.scenarioId = ''
    },
    goEdit(id) {

      let routedata = this.$router.resolve({
        path: '/scenarioResume',
        query: {
          id,
        }
      })
      window.open(routedata.href,'_blank');
      // this.isModal = true
      // this.scenarioId = id
      // this.$http.get('/scenario/itemone', {  
      //   id
      // }).then((res) => {
      //   this.scenarioDetail = res.rows
      // })
    },
    goUrl(id) {
      this.actived = id;
      this.searchSort(this.actived)
      window.scrollTo( 0, 0 );
    },
    getScenario(isClear) {
      if (isClear) {
        this.actived = 1
        this.typeSelected = []
        this.themeSelected = []
        this.searchVal = ''
        this.isType = false
        this.isTheme = false
        this.sortAsc = ''
      }
      this.$http.get('/scenario/item', {  
        pageSize: 10,
        pageNum: this.actived
      }).then((res) => {
        this.scenarioList = res.rows
        this.total = Math.round(res.total / 10)
        this.codeimg = res.codeimg
      })
    },
  },
  created() {
    this.getScenario()
    this.getTheme()
    this.getType()
    window.scrollTo( 0, 0 );
  },
}
</script>

<style scoped lang='less'>

.xzys{
  background:#ECBC3B;
}

.scenario-nav {
  padding-top: 60px;
  width: 1200px;
  margin: 0 auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-input {
    width: 348px;
    height: 34px;
    border-radius: 18px;
    border: 1px solid #000;
    text-indent: 14px;
    font-size: 14px;
    outline: 0;
  }

  .nav-left {
    display: flex;
    div {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 22px;
      cursor: pointer;
      position: relative;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    p {
      position: absolute;
      left: 0;
      width: 130px;
      top: 30px;
      z-index: 99;
      display: flex;
      flex-direction: column;
      line-height: 30px;
      padding-left: 15px;
      font-size: 14px;
      max-height: 370px;
      overflow-y: scroll;
      padding-top: 15px;
      background: #F8F8F8;
      box-shadow: 0px 6px 23px 1px rgba(4, 0, 0, 0.24);
      border-radius: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-right:8px;
      }
      span {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          padding-top: 25px;
        }
      }
    }
    .theme-box {
      width: 520px;
      display: flex;
      flex-wrap: wrap;
      overflow: visible;
      padding-top:0;
      span {
        width: 90px;
        padding-top: 15px!important;
        i {
          width: 66px;
          font-style:normal;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        
      }
    }
  }

  .nav-right {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #000000;
    line-height: 20px;
    padding: 7px 20px;
    border: 1px solid #000;
    border-radius: 17px;
  }

}
.scenario-box {
  display: flex;
  justify-content: center;
  background: #f8f8f8;
  // flex-direction: column;
  // align-items: center;

  .scenario-right {
    width: 822px;
    margin-right: 30px;
  }

  .page-content {
    margin-top: 30px;
    margin-bottom: 60px;
  }


  .box-column {
    width: 822px;
    margin-top: 24px;
    border-radius: 6px;
    // border: 1px solid #ddd;
    background: #ffffff;
    padding: 20px;
    display: flex;
    position: relative;
    
    &:not(:first-child) {
      margin-top: 30px;
    }
    
    .box-collection {
      cursor: pointer;
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 20px;
      height: 20px;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .box-star {
      position: absolute;
      top: 13px;
      right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .star-num {
        font-size: 30px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 42px;
      }

      .star {
        color: #B92828;
        height: 12px;
      }
    }

    .box-img {
      width: 150px;
      height: 220px;
      display: block;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img { 
        width: auto;
        height: auto;
        max-height: 100%;
      }
    }

    .box-msg {
      flex: 1;

      .name {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 16px;
      }

      .msg {
        width: 580px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 21px;
        margin-top: 15px;
        
      }
      .msg-one {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .msg-line {
        overflow : hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .ewmyc{
        position:absolute;
        right:0px;
        top:0px;
        border-radius: 0 20px 0 0;
      }
      .ewm{
        display:block;
        width:200px;
        height:200px;
        position:absolute;
        right:50px;
        top:0px;
        z-index:9;
        border:1px solid #000;
        border-radius:10px;
        //display:none;
      }
    }
  }
}

.ellipsis {
	overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
}
.wid100 {
	width:0;
}
.scenario-edit-box {
	width: 700px;
	background: #fff;
	padding: 15px;
	margin: 0 auto;
	.edit-btn {
		width: 100%;
		height: 20px;
    display: flex;
    padding: 22px 0 22px 16px;
    justify-content: space-between;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    .btn-close {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #ddd;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ddd;
      margin-left: 10px;
    }
		.btn {
			width: 20px;
      height: 20px;
      border-radius: 50%;
			background: #d57d7d;
			margin-left: 10px;
		}
	}
	.edit-user-info {
		width: 100%;
    padding: 14px 26px;
		display: flex;
    margin-top: 29px;
    background: #F2F2F2;
    border-radius: 8px;
		.user-img {
			width: 125px;
			height: 125px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
		}
		.user-info-center, .user-info-right {
			flex-grow: 1;
			width: 0;
			display: flex;
			flex-direction: column;
			margin-left: 20px;
			p {
				font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
				margin-top: 20px;
				&:nth-child(1) {
					margin-top: 15px;
				}
			}
		}
	}

	.section-tip {
    margin-top: 12px;
    margin-bottom: 8.5px;
    margin-left:16px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
	}
	.section-desc {
    padding-left:16px;
    background: #F2F2F2;
    border-radius: 8px;
    padding: 15px;
		font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    opacity: 0.6;
	}
	.section-tittle {
		font-size: 14px;
		font-family: PingFangSC, PingFangSC-Medium;
		font-weight: 500;
		text-align: left;
		color: #000000;
		margin-top: 8px;
	}
	.section-tittle-desc {
		font-size: 14px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		text-align: left;
		color: #666666;
		margin-top: 5px;
	}

  .xhy{
    width: 0px;
    margin: 0px;
    padding: 0px;
  }
  .bxs{
    display:none;
  }
}
</style>
