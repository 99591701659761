<template>
  <div class='drama-box'>
    <div class='drama-right'>
      <div class='drama-nav'>
        <div class='nav-tab'>
          <p @click='toBlackList'>全部剧本</p>
          <p class='tab-actived'>剧本黑马</p>
        </div>
        <div class='nav-right'>
          设置提醒
        </div>
        <div class='search-input-box'>
          <input type="text" placeholder="请输入关键字" class="search-input" v-model="searchVal" @change='searchSort(1)'>
          <img src='https://www.jubenchaoshi.cn/img/so.png'/>
        </div>
      </div>
      <div class='nav-left'>
        <div class='actived'>全部题材</div>
        <div>价格</div>
        <div>类型</div>
        <div>版权状态</div>
        <div>排序</div>
      </div>
      <div class='drama-list'>
        <div class='box-column' @click="goDetail(item.id)" v-for='(item, key) in dramaList' :key='key'>
          <img :src="item.img" alt="" class='box-img'>
          <div class='box-msg'>
            <div class='name'>{{item.title}}</div>
            <p class='msg'>作者：{{item.name}}</p>
            <p class='msg'>题材：{{item.classify}} {{item.theme}}</p>
            <p class='msg-dec'>简介：{{item.desction}}</p>
          </div>
          <div class='box-star'>
            <div class='star-num'>{{item.xprrce}}</div>
            <div class='star'>市场参考价</div>
          </div>
          <div class='box-collection'>
            <img src="https://www.jubenchaoshi.cn/img/sc.png" alt="">
          </div>
        </div>
      </div>
      <div class='page-content'>
        <div @click='toPage("index")'>首页</div>
        <div @click='toPage("prev")'>上一页</div>
        <!-- <div v-for='(item, key) in total' :key='key' @click='toPage(key)'>{{key+1}}</div> -->
        <div @click='toPage("next")'>下一页</div>
        <div @click='toPage("end")'>尾页</div>
      </div>
    </div>
    <Right-Search />
  </div>
</template>

<script>
import RightSearch from '../../components/RightSearch'

export default {
  components: {
    RightSearch
  },
  data () {
    return {
      dramaList: [],
      actived: 1,
      total: 0
    }
  },
  methods: {
    toBlackList() {
      this.$router.push({
        path: '/drama'
      })
    },
    goDetail(id) {
      this.$router.push({
        path:'/drama/detail',
        query: {
          id
        }
      })
    },
    toPage(type) {
      switch (type) {
        case 'index':
          this.actived = 1;
          break;
        case 'prev':
          this.actived = this.actived > 1 ? this.actived-1 : 1;
          break;
        case 'next':
          this.actived = this.actived+1;
          break;
        case 'end':
          this.actived = this.total;
          break;
      }
      this.getDrama()
      window.scrollTo( 0, 0 );
    },
    getDrama() {
      this.$http.get('/drama/item2', {  
        pageSize: 10,
        pageNum: this.actived
      }).then((res) => {
        this.dramaList = res.rows
        this.total = Math.round(res.total / 10)
      })
    },
  },
  created() {
    window.scrollTo(0,0)
    this.getDrama()
  },
}
</script>

<style scoped lang='less'>
.drama-box {
  display: flex;
  justify-content: center;

  .drama-right {
    margin-right: 30px;
  }

  .nav-left {
    display: flex;
    margin-top: 20px;
    div {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 22px;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    .actived {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 22px;
    }
  }

  .drama-nav {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-tab {
      display: flex;
      .tab-actived {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 18px;
        border-bottom: 2px solid #000000;
      }
      p {
        height: 28px;
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        line-height: 18px;
        margin-right: 22px;
      }
    }
    .nav-right {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 20px;
      padding: 7px 20px;
      border: 1px solid #000;
      border-radius: 17px;
    }

  }

  .drama-list {
    
    .box-column {
      width: 822px;
      display: flex;
      border: 1px solid #e4e4e4;
      border-radius: 7px;
      padding: 20px;
      margin-top: 24px;
      position: relative;
      background: #fff;

      &:not(:first-child) {
        margin-top: 30px;
      }

      .box-img {
        width: 280px;
        height: 160px;
        display: block;
        margin-right: 20px;
      }

      .box-msg {
        flex: 1;

        .name {
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 16px;
        }

        .msg {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 14px;
          margin-top: 16px;
        }

        .msg-dec {
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 21px;
          width: 400px;
        }
      }

      .box-star {
        position: absolute;
        top: 13px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .star-num {
          font-size: 30px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 30px;
        }

        .star {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 17px;
          margin-top: 5px;
        }
      }

      .box-collection {
        cursor: pointer;
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }

  }

  .page-content {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
</style>
