<template>
	<div class="center-box">
		<div class="user-left">
			<div class="user-message">
				<img :src="userImg" alt="" class="user-img">
				<p class="user-id">{{userObj.name}}</p>
				<p class="user-tip">完善信息</p>
				<div class="open-vip">开通会员</div>
			</div>
			<div class="user-menu">
				<p v-for="(item, index) in tabList" :key="index" :class="activeTab == item ? 'menu-active' : ''" @click="changeTab(item)">{{item}}</p>
			</div>
		</div>
		<!-- 我的收藏 -->
		<div class="user-right-1" v-if="activeTab == '我的收藏'">
			<div class="type-tab">
				<p :class="checkoutMyCollectionTab == '1' ? 'tab-active':''" @click="checkoutMyCollection(1)">编剧({{collectionList.scenario.total}})</p>
				<p :class="checkoutMyCollectionTab == '2' ? 'tab-active':''" @click="checkoutMyCollection(2)">剧本（{{collectionList.drama.total}}）</p>
				<p :class="checkoutMyCollectionTab == '3' ? 'tab-active':''" @click="checkoutMyCollection(3)">制片方（{{collectionList.production.total}}）</p>
				<p :class="checkoutMyCollectionTab == '4' ? 'tab-active':''" @click="checkoutMyCollection(4)">任务（{{collectionList.taskhall.total}}）</p>
			</div>
			<div class="user-list" v-if='checkoutMyCollectionTab == "1"'>
				<div class="list-item" v-for='(item, key) in collectionList.scenario.list' :key='key'>
          <div class="item-img">
            <img :src="item.img" alt="">
          </div>
					<p class="item-name">{{item.title}}</p>
					<p class="item-desc">{{item.desction}}</p>
				</div>
        <div v-if='!collectionList.scenario.list.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>

			</div>
			<div class="user-list" v-if='checkoutMyCollectionTab == "2"'>
				<div class="list-item drama-item" v-for='(item, key) in collectionList.drama.list' :key='key' @click='goDramaDetail(item.id)'>
          <div class="item-img">
            <img :src="item.img" alt="">
          </div>
					<p class="item-name">{{item.title}}</p>
					<p class="item-desc">{{item.desction}}</p>
				</div>
        <div v-if='!collectionList.drama.list.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>

			</div>
			<div class="user-list" v-if='checkoutMyCollectionTab == "3"'>
				<div class="list-item" v-for='(item, key) in collectionList.production.list' :key='key' @click='goProDetail(item.id)'>
          <div class="item-img">
            <img :src="item.img" alt="">
          </div>
					<p class="item-name">{{item.title}}</p>
					<p class="item-desc">{{item.desction}}</p>
				</div>
        <div v-if='!collectionList.production.list.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>

			</div>
			<div class="user-list" v-if='checkoutMyCollectionTab == "4"'>
				<div class="list-item taskhall-item" v-for='(item, key) in collectionList.taskhall.list' :key='key' @click='goTaskDetail(item.id)'>
          <div class="item-img ">
            <img :src="item.img" alt="">
          </div>
					<p class="item-name">{{item.title}}</p>
					<p class="item-desc">{{item.desction}}</p>
				</div>
        <div v-if='!collectionList.taskhall.list.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>
			</div>
		</div>

		<!-- 我的上传 -->
		<div class="user-right-3" v-if="activeTab == '我的上传'">
			<div class="type-tab">
				<p :class="checkoutMyUploadTab == '1' ? 'tab-active':''" @click="checkoutMyUpload(1)">作品（12）</p>
				<p :class="checkoutMyUploadTab == '2' ? 'tab-active':''" @click="checkoutMyUpload(2)">任务（12）</p>
			</div>
			<div class="list" v-if='checkoutMyUploadTab=="1"'>
				<div class="list-item" v-for="(item) in jubenList" :key="item" >
					<img src="" alt="" class="item-img">
					<div class="item-info">
						<div class="info-title">
							<p class="title">{{item.title}}</p>
							<div class="flex">
								<div class="edit-btn">编辑</div>
								<div class="edit-btn">取消上架</div>
							</div>
						</div>
						<p class="info-desc">作者：{{item.name}}</p>
						<p class="info-desc ellipsis mt19">题材：{{item.theme}}</p>
						<p class="info-desc ellipsis-3 mt16">简介：{{item.desction}}</p>
					</div>
				</div>
        <div v-if='!jubenList.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>
			</div>
			<div class="list" v-if='checkoutMyUploadTab=="2"'>
				<div class="list-item" v-for="(item) in renwuList" :key="item" >
					<div class="item-info">
						<div class="info-title">
							<p class="title">{{item.title}}</p>
							<div class="flex">
								<div class="edit-btn">编辑</div>
								<div class="edit-btn">取消上架</div>
							</div>
						</div>
						<p class="info-desc">作者：</p>
						<p class="info-desc ellipsis mt19">题材：</p>
						<p class="info-desc ellipsis-3 mt16">简介：</p>
					</div>
				</div>
        <div v-if='!renwuList.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>
			</div>
		</div>
		<!-- 我的订单 -->
		<!-- <div class="user-right-3" v-if="activeTab == '我的订单'">
			<div class="list" >
				<div class="list-item" v-for="(item) in orderList" :key="item" >
					<img src="" alt="" class="item-img">
					<div class="item-info">
						<div class="info-title">
							<p class="title">{{item.title}}</p>
							<div class="flex">
								<div class="edit-btn">申请退款</div>
							</div>
						</div>
						<p class="info-desc">作者：{{item.name}}</p>
						<p class="info-desc ellipsis mt19">题材：</p>
						<p class="info-desc ellipsis-3 mt16">简介：</p>
					</div>
				</div>
			</div>
      <div v-if='!orderList.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>
		</div> -->
		<!-- 我的任务 -->
		<div class="user-right-1" v-if="activeTab == '我的任务'">
			<div class="type-tab">
				<p :class="{'tab-active': taskInd === key}" v-for='(item, key) in taskList' :key='key' @click='taskInd = key'>{{item.name}}（{{item.num}}）</p>
				<!-- <p>版权需求（12）</p>
				<p>剧本推介（12）</p>
				<p>项目路演（12）</p> taskInd-->
			</div>
			<div class="user-list">
				<div class="list-item-rw" v-for="(item, key) in taskList[taskInd].data " :key="key" @click='goTaskDetail(item.id)'>
					<img :src="item.img" alt="" class="item-img">
					<p class="item-name">{{item.name}}</p>
					<p class="item-desc">{{item.demand}}</p>
				</div>
			</div>
      <div v-if='!taskList[taskInd].data.length' class='list-none'><span style='margin-right:20px;'></span>暂无数据<span style='margin-left:20px;'></span></div>

		</div>
		<!-- 个人设置 -->
		<div class='user-right-user'  v-if="activeTab == '个人设置'">
			<div class='user-box'>
				<img :src="userImg" alt="">
				<div class='user-msg'>
					<div>昵称： {{userObj.name}} <span>修改</span></div>
					<div>id：{{userObj.id}}</div>
					<div>会员类型： {{userObj.issvip ? '超级会员' : userObj.isvip ? '高级会员' : '非会员'}} <p>立即续费</p></div>
					<div style='text-decoration: underline;color:skyblue;cursor:pointer' @click='toFollow'>我的关注</div>
				</div>
				
			</div>
			<div class='user-options'>
				<div class='options-btn'>更换头像</div>
				<div class='options-msg'>
					<p>资料完善度：</p>
					<div class='line'>
						<div class='du'></div>
					</div>
					<p style='margin-left:20px;'>30%</p>
					<span>完善资料</span>
				</div>
			</div>
		</div>
		<!-- 会员中心 -->
		<div class="user-right-4" v-if="activeTab == '会员中心'">
			<div class="vip-top">
				<div class="top-container">
					<p>我的会员信息</p>
					<div class="btn">升级会员</div>
				</div>
				<div class="vip-list">
					<div class="vip-item">体验会员</div>
					<div class="vip-item">中级会员</div>
					<div class="vip-item">高级会员</div>
					<div class="vip-item">超级会员</div>
				</div>
				<div class="vip-line">
					<div class="vip-active" :style='{width: `${userObj.isFlagVip}%`}'></div>
				</div>
			</div>
			<div class="vip-title mt60">我的专属服务</div>
			<div class="serve-list">
				<div class="serve-item" v-for="item in 4" :key="item">登记著作权代办</div>
			</div>
			<div class="vip-title mt80">我的专属服务</div>
			<div class="serve-list">
				<div class="serve-item" v-for="item in 12" :key="item">登记著作权代办</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data () {
    return {
      // '我的合约''我的订单',
			tabList: ['我的收藏',  '我的上传', '我的任务', '会员中心', '个人设置'],
			activeTab: '我的收藏',
			checkoutMyUploadTab: 1, // 1：作品，2：任务
      checkoutMyCollectionTab: 1, // 1：作品，2：任务
      userImg: '',
      collectionList: {
			},
			taskList: [],
      taskInd: 0,
      orderList: [],
      jubenList: [],
      jianliList: [],
			renwuList: [],
			userObj: {}
    }
  },
  methods: {
    toFollow() {
      this.$router.push({
        path:'/typeList',
      })
    },
    changeTab(item) {
			this.activeTab = item
		},
		// 我的上传tab切换
		checkoutMyUpload(item) {
			this.checkoutMyUploadTab = item
		},
		checkoutMyCollection(item) {
			this.checkoutMyCollectionTab = item
		},
		goProDetail(id) {
      this.$router.push({
        path:'/production/detail',
        query: {
          id
        }
      })
    },
		goDramaDetail(id) {
      this.$router.push({
        path:'/drama/detail',
        query: {
          id,
          pageType: 'drama'
        }
      });
    },
		// 任务详情页
		goTaskDetail(id) {
      this.$router.push({
        path:'/taskHall/detail',
        query: {
          id
        }
      })
    },
    // 获取收藏
    getCollection() {
      this.$http.get('/userinfo/sc', {  
      }).then((res) => {
       this.collectionList = res.rows;
      })
		},
		// 获取任务
    getTask() {
      this.$http.get('/userinfo/rw', {  
      }).then((res) => {
       this.taskList = res.rows;
      })
    },
    // 获取订单
    getOrder() {
      this.$http.get('/shop/index', {  
      }).then((res) => {
       this.orderList = res.rows;
      })
    },
    // 获取剧本
    getJuben() {
      this.$http.get('/myup/juben', {  
      }).then((res) => {
       this.jubenList = res.rows;
      })
    },
    // 获取任务
    getRenwu() {
      this.$http.get('/myup/renwu', {  
      }).then((res) => {
       this.renwuList = res.rows;
      })
    },
    // 获取简历
    getJianli() {
      this.$http.get('/myup/jianli', {  
      }).then((res) => {
       this.jianliList = res.rows;
      })
		},
		// 获取简历
    getInfoUser() {
      this.$http.post('/info/user', {  
      }).then((res) => {
				this.userObj = res.rows.user;
				this.userObj.isFlagVip = this.userObj.issvip ? 76 : this.userObj.isvip ? 51 : 0
      })
    },
  },
  created() {
		this.getInfoUser()
    this.getJuben()
    this.getRenwu()
    this.getJianli()
		this.getCollection()
    this.getTask()
    // this.getOrder()
    this.userImg = window.localStorage.getItem('jb-userImg')
  }

}
</script>

<style scoped lang='less'>

// 个人设置
.user-right-user {
	width: 822px;
	background: #ffffff;
	border-radius: 6px;
	padding: 20px;
	.user-box {
		display: flex;

		img {
			width: 120px;
			height: 120px;
			margin-right: 20px;
			display: block;
			border-radius: 4px;
		}
		.user-msg {
			flex: 1;

			div {
				margin-bottom: 20px;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #000000;
				line-height: 14px;
				display: flex;
				align-items: center;

				span {
					color: #e02020;
					margin-left: 20px;
				}

				p {
					margin-left: 20px;
					font-size: 12px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					text-align: center;
					color: #000000;
					height: 24px;
					line-height: 24px;
					border: 1px solid #000000;
					border-radius: 13px;
					width: 81px;
				}
			}
		}
		
	}
	.user-options {
		display: flex;

		.options-btn {
			width: 120px;
			height: 36px;
			border: 1px solid #000000;
			border-radius: 19px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: center;
			color: #000000;
			line-height: 36px;
			margin-right: 20px;
		}

		.options-msg {
			flex: 1;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: #000000;
			line-height: 20px;
			display: flex;
			align-items: center;

			.line {
				width: 160px;
				height: 10px;
				background: #f5f5f5;
				border-radius: 5px;
				position: relative;
				overflow: hidden;

				.du {
					position: absolute;
					top: 0;
					left:0;
					width: 30px;
					height: 10px;
					background: #e02020;
				}
			}
			span {
				color: #e02020;
				margin-left: 20px;
			}
		}

	}
	.user-data {
		div {
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			text-align: left;
			color: #000000;
			line-height: 20px;

			span{
				color: #e02020;
			}
		}
	}
}


.flex {
	display: flex;
}
.mt19 {
	margin-top: 19px;
}
.mt16 {
	margin-top: 16px;
}
.mt60 {
	margin-top: 60px;
}
.mt80 {
	margin-top: 80px;
}
.ellipsis {
	overflow: hidden;
	text-overflow:ellipsis;
	white-space: nowrap;
}
.ellipsis-3 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
	line-height: 21px;
}
.center-box {
	display: flex;
	justify-content: center;
	padding-top: 30px;
	padding-bottom: 60px;
	background: #f8f8f8;
}
.user-left {
	width: 348px;
	margin-right: 30px;
	.user-message {
		width: 348px;
		height: 347px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #ffffff;
		border-radius: 6px;
		.user-img {
			width: 110px;
			height: 110px;
			border-radius: 50%;
			margin-top: 50px;
		}
		.user-id {
			font-size: 22px;
			font-family: PingFangSC, PingFangSC-Semibold;
			font-weight: 600;
			color: #000000;
			margin-top: 8px;
		}
		.user-tip {
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #d57d7d;
			margin-top: 3px;
		}
		.open-vip {
			margin-top: 26px;
			width: 156px;
			height: 50px;
			text-align: center;
			line-height: 50px;
			background: #000000;
			border-radius: 26px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #ffffff;
		}
	}

	.user-menu {
		width: 348px;
		padding: 30px 0 25px 0;
		margin-top: 30px;
		background: #ffffff;
		border-radius: 6px;
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			font-size: 18px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #999999;
			margin-top: 40px;
			cursor: pointer;
			&:nth-child(1) {
				margin-top: 0px;
			}
		}
		.menu-active {
			font-size: 18px;
			font-family: PingFangSC, PingFangSC-Semibold;
			font-weight: 600;
			color: #000000;
		}
	}
}
.user-right-1 {
	width: 842px;
	.user-list {
		margin-top: 16px;
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		.list-item-jb {
			width: 189px;
			margin-right: 20px;
			margin-bottom: 20px;

      .item-img {
        width: 189px;
        height: 106px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: auto;
          height: auto;
          max-height: 100%;
          
        }
      }
      
			
			.item-name {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #000000;
				line-height: 14px;
				margin-top: 8px;
			}
			.item-desc {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #999999;
				line-height: 14px;
				margin-top: 8px;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
			}
		}
		.list-item-zzf {
			width: 148px;
			margin-right: 20px;
			margin-bottom: 20px;

      .item-img {
        width: 148px;
				height: 148px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: auto;
          height: auto;
          max-height: 100%;
          
        }
      }
			.item-name {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #000000;
				line-height: 14px;
				margin-top: 8px;
			}
			.item-desc {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #999999;
				line-height: 14px;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
				margin-top: 8px;
			}
		}
		.list-item-rw {
			width: 260px;
			margin-right: 20px;
			margin-bottom: 20px;

      .item-img {
        width: 260px;
				height: 190px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: auto;
          height: auto;
          max-height: 100%;
          
        }
      }

			.item-name {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Semibold;
				font-weight: 600;
				text-align: left;
				color: #000000;
				line-height: 14px;
				margin-top: 10px;
			}

			.item-desc {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #999999;
				line-height: 14px;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
				margin-top: 10px;
			}
		}
		.list-item {
			width: 148px;
			height: auto;
			margin-right: 20px;
			margin-bottom: 20px;

      .item-img {
        width: 148px;
				height: 190px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: auto;
          height: auto;
          max-height: 100%;
          
        }
      }
      
			.item-name {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #000000;
				margin-top: 8px;
			}
			.item-desc {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #999999;
				margin-top: 8px;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
			}
    }
    .drama-item {
      width: 190px;
      .item-img {
        width: 190px;
        height: 106px;
      }
    }

    .taskhall-item {
      width: 260px;
      .item-img {
        width: 260px;
        height: 190px;
      }
    }
	}
}
.user-right-3 {
	width: 822px;
	.list-item {
		width: 822px;
		padding: 21px 20px;
		background: #ffffff;
		border-radius: 6px;
		margin-top: 20px;
		display: flex;

    .item-img {
      margin-right: 20px;
      width: 280px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: auto;
        height: auto;
        max-height: 100%;
        
      }
    }
		.item-info {
			width: 481px;
			flex: 1;
			display: flex;
			flex-direction: column;
			.info-title {
				display: flex;
				justify-content: space-between;
				.title {
					font-size: 16px;
					font-family: PingFangSC, PingFangSC-Semibold;
					font-weight: 600;
					text-align: left;
					color: #000000;
				}
				.edit-btn {
					height: 34px;
					border: 1px solid #000000;
					border-radius: 18px;
					padding: 0 28px;
					text-align: center;
					line-height: 34px;
					font-size: 14px;
					font-family: PingFangSC, PingFangSC-Regular;
					font-weight: 400;
					color: #000000;
					margin-left: 10px;
				}
			}

			.info-desc {
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #666666;
				width: 100%;
			}
		}
	}
}
.type-tab {
	display: flex;
	align-items: center;
	p {
		font-size: 16px;
		font-family: PingFangSC, PingFangSC-Regular;
		font-weight: 400;
		color: #999999;
		margin-right: 30px;
		cursor: pointer;
	}
	.tab-active {
		font-size: 16px;
		font-family: PingFangSC, PingFangSC-Semibold;
		font-weight: 600;
		text-align: left;
		color: #000000;
	}
}
.user-right-4 {
	width: 822px;
	.vip-top {
		width: 822px;
		background: #ffffff;
		border-radius: 6px;
		padding: 20px;
		.top-container {
			display: flex;
			justify-content: space-between;
			p {
				font-size: 16px;
				font-family: PingFangSC, PingFangSC-Semibold;
				font-weight: 600;
				text-align: left;
				color: #000000;
			}
			.btn {
				width: 156px;
				height: 50px;
				background: #000000;
				border-radius: 26px;
				line-height: 50px;
				text-align: center;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				color: #ffffff;
			}
		}
		.vip-list {
			display: flex;
			margin-top: 30px;
			.vip-item {
				flex: 1;
				font-size: 14px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #000000;
				position: relative;
				&::after {
					content: '';
					width: 1px;
					height: 11px;
					background: #979797;
					position: absolute;
					top: 26px;
					left: 0px;
				}
			}
		}
		.vip-line {
			width: 100%; 
			height: 15px;
			background: #d8d8d8;
			border-radius: 8px;
			overflow: hidden;
			position: relative;
			margin-top: 22px;
			.vip-active {
				position: absolute;
				left: 0;
				top: 0;
				// width: 358px;
				height: 15px;
				opacity: 1;
				background: #d57d7d;
				border-radius: 8px;
			}
		}
	}
	.vip-title {
		font-size: 16px;
		font-family: PingFangSC, PingFangSC-Semibold;
		font-weight: 600;
		text-align: left;
		color: #000000;
	}
	.serve-list {
		display: flex;
		flex-wrap: wrap;
		.serve-item {
			width: 148px;
			height: 126px;
			background: #000000;
			border-radius: 6px;
			line-height: 126px;
			text-align: center;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Semibold;
			font-weight: 600;
			color: #ffffff;
			margin: 10px 20px 10px 0;
			&:nth-child(5n) {
				margin-right: 0;
			}
		}
	}
}
.list-none {
  font-size: 20px;
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 100px;
    height:1px;
    background: #ccc;
  }
}
</style>