<template>
  <div class='search-content'>
    <img src="../../assets/images/logo-black.png" alt="" class='search-img'>
    <div class='search-box'>
      <input type="text" placeholder="请输入内容" v-model="searchVal">
      <span @click='getSearchList'>点击搜索</span>
    </div>
    <div class='search-list'>
      <div class='search-left'>
        <div class='search-column' v-for='(item, key) in list' :key='key' @click='toDetail(item.id, item.lxid)'>
          <div class='title' v-html='item.title'></div>
          <div class='desc'>
            <span>简介:</span>
            <p>{{item.desction}}</p>
          </div>
          <div class='desc'>
            <span>来自:</span>
            <p>{{item.lx}}</p>
          </div>
        </div>
      </div>
      <RightSearch isSearch='1' v-if='list.length'/>
    </div>
  </div>
</template>
<script>
import RightSearch from '../../components/RightSearch'

export default {
  components: {
    RightSearch
  },
  data() {
    return{
      list: [],
      searchVal: ''
    }
  },
  methods: {
    toDetail(id, rid) {
      switch (rid) {
        case 1:
          this.$router.push({
            path: '/scenarioResume',
            query: {
              id,
            }
          })
          break;
        case 2:
          this.$router.push({
            path: '/drama/detail',
            query: {
              id,
            }
          })
          break;
        case 3:
          this.$router.push({
            path: '/production/detail',
            query: {
              id,
            }
          })
          break;
        case 4:
          this.$router.push({
            path: '/taskHall/detail',
            query: {
              id,
            }
          })
          break;
      }
    },
    getSearchList() {
      this.$http.post('/soso/', {
        title: this.searchVal
      }).then((res) => {
        this.list = res.rows
      })
    }
  }
}
</script>
<style lang="less">
.search-list {
  width: 1200px;
  display: flex;
  padding-bottom: 100px;
  .search-left {
    flex: 1;
    margin-right: 15px;
  }
  .search-column {
    padding: 20px 18px; 
    background: #FFFFFF;
    margin-top: 6px;
    .title {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
    }
    .desc {
      margin-top: 16px;
      display: flex;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
      opacity: 0.6;
      span {
        width: 50px;
      }
    }
  }
}
.search-content{
  width: 1200px;
  margin: 0 auto;
}
.search-img {
  width: 127px;
  height: 35px;
  margin: 0 auto;
  display: block;
  margin-bottom: 36px;
  margin-top: 36px;
}
.search-box {
  width: 630px;
  height: 50px;
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 25px;
  // width: 752px;
  // height: 62px;
  // background: #FFFFFF;
  // border: 2px solid #000000;
  // border-radius: 30px;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  padding-left: 21px;
  margin-bottom: 20px;
  input {
    flex: 1;
    border:none;
    outline: none;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
  }
  span {
    width: 113px;
    // height: 46px;
    background: #000000;
    border: 1px solid #030000;
    border-radius: 23px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
</style>