<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <Home v-if='!isLogin' />
    <router-view v-if='isLogin'></router-view>
  </div>
</template>

<script>
import Home from './views/Home'
export default {
  name: 'App',
  data() {
    return {
      isLogin: false,  
    }
  },
  created() {
    const { path } = this.$route
    console.log(path)
    if (path === '/login') {
      this.isLogin = true
    }
  },
  components:{
    Home
  }
}
</script>

<style lang='less'>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app{
  background: #f8f8f8;
}
.page-content {
  display: flex;
  justify-content: center;

  div {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #000000;
    line-height: 20px;
    padding: 7px 14px;
    border-radius: 17px;
    border: 1px solid #000;
    margin-right: 20px;
  }
}
.search-input-box {
  position: relative;
  
  &:after{
    content: '';
    display:block;
    position: absolute;
    right:0;
    height: 34px;
    background-color: #000;
    width: 70px;
    top: 0;
    border-radius: 0 18px 18px 0;
    // background-image: url();
  }
  img {
    position: absolute;
    right:27px;
    height: 22px;
    width: 22px;
    top: 7px;
    z-index: 9;
  }
}
.search-input {
  padding-right:70px;
}
.page-content {
  align-items: center;
}
.mask-content {
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  background: rgba(0,0,0,.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .mask-box {
    width: 400px;
    height: 392px;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 50px 36px 17px 36px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .mask-close {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
    }
    .mask-img {
      width: 300px;
      height: 300px;
    }
    textarea {
      width: 350px;
      height: 262px;
      background: #FFFFFF;
      border: 1px solid #080316;
      opacity: 0.2;
      border-radius: 5px;
      padding: 15px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 300;
      color: #000000;
    }


  }
  .btn {
    margin: 14px auto;
    width: 140px;
    height: 44px;
    background: #EBC33C;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 300;
    color: #000000;
    cursor: pointer;
  }
}
</style>
