<template>
	<div class="resume-container">
		<div class="user-info">
			<div class="user-img">
				<img :src="scenarioDetail.img" alt="">
			</div>
			<!-- <img class="user-img"  alt=""> -->
			<div class="user-message">
				<div class="message-left">
					<div class="message-item">
						<img src="../../assets/images/ren@2x.png" alt="">
						<p class="title">姓 名：</p>
						<p class="desc">{{scenarioDetail.title}}</p>
					</div>
					<!-- <div class="message-item">
						<img src="../../assets/images/shengri@2x.png" alt="">
						<p class="title">年 龄：</p>
						<p class="desc"></p>
					</div> -->
					<div class="message-item">
						<img src="../../assets/images/pv_sex@2x.png" alt="">
						<p class="title">性 别：</p>
						<p class="desc">{{scenarioDetail.gender == 2? '女' : '男'}}</p>
					</div>
					<div class="message-item">
						<img src="../../assets/images/jingjiren@2x.png" alt="">
						<p class="title">经 纪 人：</p>
						<p class="desc">{{scenarioDetail.jjr}}</p>
					</div>
					<div class="message-item">
						<img src="../../assets/images/dianhua@2x.png" alt="">
						<p class="title">经纪人电话:</p>
						<p class="desc">{{scenarioDetail.jjr_phone}}</p>
					</div>
					<div class="message-item">
						<img src="../../assets/images/weibiaoti-1-14@2x.png" alt="">
						<p class="title">现 居 地：</p>
						<p class="desc">{{scenarioDetail.city}}</p>
					</div>
				</div>
				<div class="message-right">
					<div class="message-item">
						<img src="../../assets/images/dianhua@2x.png" alt="">
						<p class="title">电 话：</p>
						<p class="desc">{{scenarioDetail.phone}}</p>
						<img src="../../assets/images/yanjing@2x.png" alt="" class="ml16" @click='getPhone'>
					</div>
          <img src="../../assets/images/share.png" alt="" class='share-right' @click='isShare=true'>
          <!-- <div class='share-content' v-if='isShare'>
            <span @click='isShare=false'>x</span>
            <img :src="scenarioDetail.codeimg" alt="">
          </div> -->
				</div>
			</div>
		</div>

		<div class="resume-desc">
			<div class="desc-title">个人介绍</div>
			<div class="desc desc-1">{{scenarioDetail.desction}}</div>
		</div>

		<div class="resume-desc">
			<div class="desc-title">工作经历</div>
			<div class="desc desc-2">{{scenarioDetail.ggjl}}
				<!-- <div class="desc-align">
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
				</div>
				<div class="desc-align">
					<div class="each-align">
						<p>· 电影</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
				</div> -->
			</div>
		</div>

		<div class="resume-desc">
			<div class="desc-title">教育经历</div>
			<div class="desc desc-3">
				<div class="each-align">{{scenarioDetail.school}}</div>
				<!-- <div class="each-align">2020-2021    北京传媒大学    博士生</div>
				<div class="each-align">2020-2021    北京传媒大学    博士生</div> -->
			</div>
		</div>

		<div class="resume-desc">
			<div class="desc-title">获奖作品</div>
			<div class="desc desc-4">{{scenarioDetail.hjzp}}
				<!-- <div class="desc-align">
					<div class="each-align">
						<p>1.警匪动作类型电影《未婚监狱》</p>
					</div>
					<div class="each-align">
						<p>3.警匪动作类型电影《未婚监狱》</p>
					</div>
				</div>
				<div class="desc-align">
					<div class="each-align">
						<p>2.警匪动作类型电影《未婚监狱》</p>
					</div>
				</div> -->
			</div>
		</div>

		<div class="resume-desc">
			<div class="desc-title">主要作品</div>
			<div class="desc">
				<div class="desc-4">{{scenarioDetail.old}}
					<!-- <div class="type">已上线</div>
					<div class="desc-align">
						<div class="each-align">
							<p>1.警匪动作类型电影《未婚监狱》</p>
						</div>
						<div class="each-align">
							<p>3.警匪动作类型电影《未婚监狱》</p>
						</div>
					</div>
					<div class="desc-align">
						<div class="each-align">
							<p>2.警匪动作类型电影《未婚监狱》</p>
						</div>
					</div> -->
				</div>
				<!-- <div class="desc-4 mt25">
					<div class="type">未上线</div>
					<div class="desc-align">
						<div class="each-align">
							<p>1.警匪动作类型电影《未婚监狱》</p>
						</div>
						<div class="each-align">
							<p>3.警匪动作类型电影《未婚监狱》</p>
						</div>
					</div>
					<div class="desc-align">
						<div class="each-align">
							<p>2.警匪动作类型电影《未婚监狱》</p>
						</div>
					</div>
				</div> -->
			</div>
		</div>

		<div class="resume-desc">
			<div class="desc-title">擅长类型</div>
			<div class="desc desc-2">
				<div class='desc-column' v-for='(item, key) in scenarioDetail.theme.split("、")' :key='key'>{{item}}</div>
			</div>
		</div>

		<div class="resume-desc">
			<div class="desc-title">擅长题材</div>
			<div class="desc desc-2">
				<div class='desc-column' v-for='(item, key) in scenarioDetail.classify.split("、")' :key='key'>{{item}}</div>

				<!-- <div class="desc-align">
					<div class="each-align">
						<p>{{scenarioDetail.classify}}</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
				</div>
				<div class="desc-align">
					<div class="each-align">
						<p>· 电影</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
					<div class="each-align">
						<p>· 电视剧</p>
					</div>
				</div> -->
			</div>
		</div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="scenarioDetail.codeimg" alt="" class='mask-img'>
      </div>
    </div>
		<div class='mask-content' v-if='isPhone'>
      <div class='mask-box' style='padding:40px;height:auto;'>
				<p>{{scenarioPhone}}</p>
        <img src="../../assets/images/guanbi.png" alt="" @click='isPhone=false' class='mask-close'>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  name: 'Index',
  data () {
    return {
			scenarioDetail: {},
			isShare: false,
			isPhone: false,
			scenarioPhone: ''
		}
	},
	methods: {
		getDetail (id) {
			this.$http.get('/scenario/itemone', {  
				id
			}).then((res) => {
				this.scenarioDetail = res.rows
			})
		},
		getPhone () {
			this.isPhone = true
			this.$http.post('/api/phone', {  
				id: this.$route.query.id,
				type: 1
			}).then((res) => {
				if (!res.code) {
					this.scenarioPhone = `本号码${res.time}时间前有效${res.rows}`
				} else {
					this.scenarioPhone = res.msg
				}
			})
		}
	},
	created() {
		this.getDetail(this.$route.query.id)
	}
}
</script>

<style scoped lang='less'>
.resume-container {
	width: 1000px;
	margin: 0 auto;
	margin-top: 68px;
	margin-bottom: 100px;
	background: #fff;
	padding: 20px;
	box-shadow: 0px 0px 10px #ddd; 
	border-radius: 10px;
	.user-info {
		width: 100%;
		display: flex;
		.user-img {
			width: 150px;
			height: 206.5px;
			margin-right: 60px;
			display: flex;
			justify-content: center;
			align-items: center;
			overflow: hidden;
			img {
				width: auto;
				height: auto;
				max-height: 100%;
			}
			
		}
		.user-message {
			flex: 1;
			display: flex;
			.message-left, .message-right {
        position: relative;
				width: 380px;
				.message-item {
					display: flex;
					align-items: center;
					margin-bottom: 22px;
					img {
						width: 22px;
						height: auto;
					}
					.title {
						width: 85px;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #000000;
						margin-left: 10px;
					}
					.desc {
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #7E7E7E;
						margin-left: 20px;
					}
					&:last-child {
						margin-bottom: 0px;
					}
        }
        .share-right {
          position: absolute;
          right: 0;
          top: 0;
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
        .share-content {
          position: absolute;
          right: 0px;
          top: -245px;
          width: 215px;
          height: 245px;
          background: #fff;
          padding: 10px 20px 20px 20px;
          border-radius: 10px;
          span {
            font-size: 20px;
            color: #000;
            text-align: right;
            padding-right: 10px;
            display: block;
          }
          img {
            display: block;
            width: 175px;
            height: 185px;
          }
        }
			}
		}
	}

	.resume-desc {
		width: 100%;
		display: flex;
		margin-top: 50px;
		
		.desc-title {
			width: 101px;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 600;
			color: #000000;
			letter-spacing: 3px;
		}
		.desc {
			width: 1069px;
			// flex-grow: 1;
		}
		// 个人介绍
		.desc-1 {
			font-size: 16px;
			color: #585858;
			line-height: 25px;
		}
		// 工作经历
		.desc-2 {
			display: flex;
			flex-wrap: wrap;
			.desc-column{
				color: #8D8D8D;
				font-size: 18px;
				width: 360px;
				overflow: hidden;
        text-overflow:ellipsis;
				white-space: nowrap;
				margin-bottom: 18px;
			}
			.desc-align {
				min-width: 360px;
				.each-align {
					margin-top: 18px;
					&:first-child {
						margin-top: 0;
					}
					p {
						color: #8D8D8D;
						font-size: 18px;
					}
				}
			}
		}
		// 教育经历
		.desc-3 {
			.each-align {
				color: #8D8D8D;
				font-size: 16px;
				margin-top: 19px;
				&:first-child {
					margin-top: 0px;
				}
			}
		}

		// 获奖作品/主要作品
		.desc-4 {
			display: flex;
			.type {
				width: 50px;
				margin-right: 24px;
				font-size: 16px;
				// font-weight: bold;
			}
			.desc-align {
				min-width: 360px;
				.each-align {
					margin-top: 10px;
					&:first-child {
						margin-top: 0;
					}
					p {
						color: #8D8D8D;
						font-size: 18px;
					}
				}
			}
		}
	}
}
.ml16 {
	margin-left: 16px;
}
.mt25 {
	margin-top: 25px;
}
</style>