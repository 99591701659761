// import home from '../views/Home'
import index from '../views/Index'
import scenario from '../views/Scenario'
import scenarioEdit from '../views/Scenario/edit'
import drama from '../views/Drama'
import dramaDetail from '../views/Drama/detail'
import production from '../views/Production'
import productionDetail from '../views/Production/detail'
import taskHall from '../views/TaskHall'
import taskHallDetail from '../views/TaskHall/detail'
import dramaHall from '../views/DramaHall'
import userCenter from '../views/UserCenter'
import Login from '../views/Login'
import NewDetail from '../views/NewDetail'
import Content from '../views/Content'
import About from '../views/Content/about'

import BlackList from '../views/BlackList'
import Services from '../views/Services'
import Success from '../views/Success'

import Resume from '../views/Resume'
import Search from '../views/Search'
import TypeList from '../views/TypeList'


export default [
  { path: '*', redirect: '/index' },
  {
    path:"/login",
    component: Login
  },
  {
    path:"/typeList",
    component: TypeList
  },
  {
    path:"/search",
    component: Search
  },
  {
    path:"/index",
    component: index
  },
  {
    path:"/scenario",
    name: '编剧库',
    component: scenario
  },
  {
    path:"/scenario/edit",
    name: '编剧简历',
    component: scenarioEdit
  },
  {
    path:"/drama",
    name: '剧本库',
    component: drama
  },
  {
    path:"/success",
    name: '专享服务',
    component: Services
  },
  {
    path:"/services",
    name: '成功案例',
    component: Success
  },
  {
    path:"/drama/detail",
    name: '编剧库详情页',
    component: dramaDetail
  },
  {
    path:"/production",
    name: '制作库',
    component: production
  },
  {
    path:"/production/detail",
    name: '制作库',
    component: productionDetail
  },
  {
    path:"/taskHall",
    name: '任务大厅',
    component: taskHall
  },
  {
    path:"/taskHall/detail",
    name: '任务大厅-任务详情页',
    component: taskHallDetail
  },
  {
    path:"/scriptHall",
    name: '剧本堂',
    component: dramaHall
  },
  {
    path:"/userCenter",
    name: '用户中心',
    component: userCenter
  },
  {
    path:"/newDetail",
    name: '新闻详情页',
    component: NewDetail
  },
  {
    path:"/content",
    name: '内容页',
    component: Content
  },
  {
    path:"/about",
    name: '关于我们',
    component: About
  },
  {
    path:"/blackList",
    name: '剧本黑马',
    component: BlackList
  },
  {
    path:"/scenarioResume",
    name: '简历',
    component: Resume
  },
]