<template>
  <div class='drama-content'>
    <div class='drama-nav'>
      <div style='display: flex;justify-content:space-between;padding-right: 30px;flex:1;'>
        <div class='nav-left'>

        </div>
      </div>
      
      <div class='search-input-box'>
        <input type="text" placeholder="请输入关键字" class="search-input" v-model="searchVal" @change='searchSort(1)'>
        <img src='https://www.jubenchaoshi.cn/img/so.png'/>
      </div> 
    </div>
    <div class='drama-box'>
      <div class='drama-right'>
        <div class='drama-list'>
          <div class='box-column' @click="goDetail(item.id)" v-for='(item, key) in dramaList' :key='key'>
            <img :src="item.img" alt="" class='box-img'>
            <div class='box-msg'>
              <div class='name'>{{item.title}}</div>
              <p class='msg'>成交公司：{{item.join}}</p>
              <!-- <p class='msg'>题材：{{item.name}}</p>
              <p class='msg'>编剧：{{item.name}}</p>
              <p class='msg'>编剧信息：{{item.name}}</p>
              <p class='msg'>擅长：{{item.name}}</p> -->
            </div>
            <div class='box-star'>
              <p class='msg'>项目类型：{{item.tag}}</p>
              <!-- <img src="../../assets/images/show.png" alt="" v-if='!item.isNoSee' @click='toCheck(true, key)'>
              <img src="../../assets/images/hide.png" alt="" v-if='item.isNoSee' @click='toCheck(false, key)' class='no-see'> -->
              <!-- <div class='star-box'>
                <div class='star-num'>{{item.isNoSee ? '******' : item.price}}</div>
                <div class='star'>任务报酬</div>
              </div> -->
              
            </div>
            <div class='box-collection'>
              成交模式{{item.state}}
            </div>
          </div>
        </div>
        <div class='page-content'>
          <div @click='toPage("index")'>首页</div>
          <div @click='toPage("prev")'>上一页</div>
          <!-- <div v-for='(item, key) in total' :key='key' @click='toPage(key)'>{{key+1}}</div> -->
          <div @click='toPage("next")'>下一页</div>
          <div @click='toPage("end")'>尾页</div>
        </div>
      </div>
      <!-- <Right-Search /> -->
      <div class='right-search'>
        <!-- <div class='data-box' v-for='(item, key) in rightData' :key='key'>
          <div class='data-title'>推荐剧本</div>
          <div class='data-name'>{{item.title}}</div>
          <div class='data-msg'>{{item.tag}}{{item.price}}{{item.state}}</div>
          <div class='data-btn'>点击查看详情</div>
        </div> -->
        <div class='black-list'>
          <div class='title'>剧本黑马</div>
          <div class='black-column' v-for='(item, key) in blackList' :key='key' @click='goDetail1(item.id)'>
            <div class='box-img'>
              <img :src="item.img" alt="">
            </div>
            <div class='column-msg'>
              <div>{{item.title}}</div>
              <p>编剧：{{item.name}}</p>
              <p>题材：{{item.theme}}</p>
              <p>简介：{{item.desction}}</p>
            </div>
          </div>
          <div class='black-more' @click='toBlack'>查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      dramaList: [],
      rightData: [],
      actived: 1,
      total: 0,
      searchVal: '',
      blackList: []
    }
  },
  methods: {
    goDetail1(id) {
      this.$router.push({
        path:'/drama/detail',
        query: {
          id,
          pageType: 'blackList'
        }
      })
    },
    toBlack() {
      if (this.isBlack) {
        console.log(1, this.isBlack)
        if (this.isBlack == 'drama') {
          console.log(1)
          this.$emit('changeType', 'blackList')
        }
        window.scrollTo(0,0);

        return;
      }
      this.$router.push({
        path: '/drama',
        query: {
          pageType: 'blackList'
        }
      })
    },
    getBlackList() {
      this.$http.get('/drama/item2', {  
        pageSize: 3,
        pageNum: 1
      }).then((res) => {
        this.blackList = res.rows
      })
    },
    toCheck(type, ind) {
      this.dramaList[ind].isNoSee = type;
    },
    searchSort(num) {
      this.actived = num
      let params = {  
        pageSize: 10,
        pageNum: this.actived
      }
      if(this.searchVal) params.title = this.searchVal
     

      this.$http.get('/trade/item', params).then((res) => {
        this.dramaList = res.rows
        this.total = Math.round(res.total / 10)
      })
    },
    goDetail() {
      // this.$router.push({
      //   path:'/trade/detail',
      //   query: {
      //     id
      //   }
      // })
    },
    toPage(type) {
      switch (type) {
        case 'index':
          this.actived = 1;
          break;
        case 'prev':
          this.actived = this.actived > 1 ? this.actived-1 : 1;
          break;
        case 'next':
          this.actived = this.actived+1;
          break;
        case 'end':
          this.actived = this.total;
          break;
      }
      this.searchSort(this.actived)
      window.scrollTo( 0, 0 );
    },
    getDrama() {
      this.$http.get('/trade/item', {  
        pageSize: 10,
        pageNum: this.actived
      }).then((res) => {
        this.dramaList = res.rows.map((val) => {
          val.isNoSee = false;
          return val;
        })
        this.total = Math.round(res.total / 10)
      })
      this.$http.get('/trade/righthot', {  
      }).then((res) => {
        this.rightData = res.rows
      })
    },
  },
  created() {
    this.getDrama();
    this.getBlackList();
    window.scrollTo( 0, 0 );
  },
}
</script>

<style scoped lang='less'>
.right-search {
  width: 348px;
  .data-box {
    padding: 12px 27px 16px 27px;
    margin-top: 24px;
    background: #FFFFFF;
    border-radius: 6px;
    .data-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
    }
    .data-name {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
      text-align: center;
      margin-top: 10px;
    }
    .data-msg {
      padding: 19px 0;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
    }
    .data-btn {
      margin: 0 auto;
      width: 156px;
      padding: 15px 36px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
      background: #000000;
      border-radius: 26px;
      cursor: pointer;
    }
  }
}
.drama-nav {
  width: 1200px;
  padding-top:60px;
  margin: 0 auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    display: flex;
    div {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 22px;
      cursor: pointer;
      position: relative;
      &:not(:first-child) {
        margin-left: 30px;
      }
    }

    p {
      position: absolute;
      left: 0;
      width: 130px;
      top: 30px;
      z-index: 99;
      display: flex;
      flex-direction: column;
      line-height: 30px;
      padding-left: 15px;
      font-size: 14px;
      max-height: 370px;
      overflow-y: scroll;
      padding-top: 15px;
      background: #F8F8F8;
      box-shadow: 0px 6px 23px 1px rgba(4, 0, 0, 0.24);
      border-radius: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-right:8px;
      }
      span {
        display: flex;
        align-items: center;
        &:not(:first-child) {
          padding-top: 25px;
        }
      }

    }
    .theme-box {
      width: 520px;
      display: flex;
      flex-wrap: wrap;
      overflow: visible;
      padding-top:0;
      span {
        width: 90px;
        padding-top: 15px!important;
        i {
          width: 66px;
          font-style:normal;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        
      }
    }
    
  }

  .search-input {
    width: 348px;
    height: 34px;
    border-radius: 18px;
    border: 1px solid #000;
    text-indent: 14px;
    font-size: 14px;
    outline: 0;
  }
  
  .nav-right {
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #000000;
    line-height: 20px;
    padding: 7px 20px;
    border: 1px solid #000;
    border-radius: 17px;
  }

}
.drama-box {
  display: flex;
  justify-content: center;

  .drama-right {
    margin-right: 30px;
  }

  .drama-list {
    
    .box-column {
      width: 822px;
      display: flex;
      border: 1px solid #e4e4e4;
      border-radius: 7px;
      padding: 20px;
      margin-top: 24px;
      position: relative;
      background: #fff;

      &:not(:first-child) {
        margin-top: 30px;
      }

      .box-img {
        width: 280px;
        height: 160px;
        display: block;
        margin-right: 20px;
      }

      .box-msg {
        flex: 1;

        .name {
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 16px;
        }

        .msg {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 14px;
          margin-top: 16px;
        }

        .msg-dec {
          margin-top: 16px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 21px;
          width: 400px;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }

      .box-star {
        position: absolute;
        top: 26px;
        right: 30px;
        display: flex;
        align-items: center;
        .star-box {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        img {
          margin-right: 30px;
          width: 27px;
          height: 20px;
          cursor: pointer;
        }
        .no-see {
          height: 11px;
        }
        .star-num {
          font-size: 30px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 30px;
        }

        .star {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 17px;
          margin-top: 5px;
        }
      }

      .box-collection {
        // cursor: pointer;
        position: absolute;
        bottom: 20px;
        right: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5BB00;
        // width: 20px;
        // height: 20px;

        // img {
        //   width: 100%;
        //   height: 100%;
        //   display: block;
        // }
      }
    }

  }

  .page-content {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}
  .black-list {
    width: 348px;
    border: 1px solid #e4e4e4;
    border-radius: 7px;
    padding: 20px;
    margin-top: 30px;
    background: #fff;
    
    .title {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #000000;
      line-height: 22px;
    }

    .black-column {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      .box-img {
        width: 110px;
        height: 140px;
        display: block;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        img { 
          width: auto;
          height: auto;
          max-height: 100%;
        }
      }
      

      .column-msg {
        flex: 1;
        div {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 14px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 21px;
          margin-top: 6px;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

    .black-more {
      margin: 30px auto 0 auto;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      line-height: 20px;
      border: 1px solid #000000;
      border-radius: 27px;
      width: 170px;
      padding: 16px 0;
      cursor: pointer;
    }
  }
</style>
