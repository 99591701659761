<template>
  <div class='footer-component'>
    <div class='footer-box'>
      <div class='footer-page'>
        <div class='page-column'>
          <div class='title'>关于我们</div>
          <p v-for='(item, key) in footerList[1]' :key='key' @click='toContent(item)'>{{item.title}}</p>
        </div>
        <div class='page-column'>
          <div class='title'>联系我们</div>
          <p v-for='(item, key) in footerList[5]' :key='key' >{{item.title}}</p>
        </div>
        <div class='page-column'>
          <div class='title'>官方微信公众号</div>
          <img src="https://www.jubenchaoshi.cn/img/jbcs.jpg" alt="">
          <span>剧本超市</span>
        </div>
        <div class='page-column'>
          <div class='title'>官方微信服务号</div>
          <img src="https://jbcsnew.oss-cn-beijing.aliyuncs.com/202304/e89e298965781537e265d678ec7dde51fdf10a8c.jpg" alt="">
          <span>剧本超市服务平台</span>
        </div>
        <div class='page-column'>
          <div class='title'>官方小程序</div>
          <img src="https://jbcsnew.oss-cn-beijing.aliyuncs.com/202304/80c4c0c6af277cd65cfb2528f698294157082672.jpg" alt="">
          <span>剧本版权超市</span>
        </div>
      </div>
      <div class='footer-copyright'>
        Copyright @ 2015-2019 剧本超市 版权所有 京ICP备17046048号-5
      </div>
    </div>
    <!-- <div class='mask-content' v-if='isShow'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShow=false' class='mask-close'>
        <img src="https://www.jubenchaoshi.cn/img/xcx.jpg" alt="" class='mask-img'>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      footerList: {},
      isShow: false,
    }
  },
  methods: {
    toContent(item) {
      if (item.tiao === 1) {
        this.$router.push({
          path: 'about',
          query: {
            id: item.id
          }
        })
      }
      if (item.tiao === 2) {
        window.open(item.url)
      }
    },
    getList() {
      this.$http.get('/about/item').then((res) => {
        this.footerList = res.rows
      })
    },
  },
  created() {
    this.getList()
  }
}
</script>

<style scoped lang='less'>
.footer-component {
  width: 100%;
  // height: 485px;
  background: #000;

  .footer-box {
    width: 1200px;
    margin: 0 auto;
  }

  .footer-copyright {
    padding: 30px 0;
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #999999;
    line-height: 22px;
  }

  .footer-page {
    padding: 30px 0 ;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: space-between;

    .page-column {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        line-height: 22px;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #999999;
        line-height: 20px;
        margin-top: 10px;
        cursor: pointer;
      }

      img {
        width: 120px;
        height: 120px;
        margin-top: 10px;
        display: block;
      }

      span {
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 12px;
        margin-top: 8px;
      }
    }
  }

  .footer-slogn {
    padding: 49px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;

    .slogn-left {
      p {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 20px;
      }
      div {
        font-size: 30px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        line-height: 42px;
      }
    }

    .slogn-right {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #ffffff;
      line-height: 20px;
      width: 180px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #fff;
      border-radius: 25px;
      position: relative;
      cursor: pointer;
      .share-img {
        position: absolute;
        top: 0px;
        left: -215px;
        width: 215px;
        height: 245px;
        background: #fff;
        .xcx {
          display: block;
          margin-top: 20px;
          width: 215px;
          height: 225px;
        }
      }
      .gb {
        position: absolute;
        top: 10px;
        right:10px;
        width: 10px;
        height: 10px;
        display: block;
      }
    }
  }

}
</style>
