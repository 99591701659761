<template>
  <div>
    <Header :isHeaderPut=isHeaderPut :headerBanner='headerBanner' :isAbout='isAbout' v-if='isLogin'/>
    <router-view :key="$route.query.id"></router-view>
    <Footer v-if='isLogin' />
  </div>
</template>

<script>
import Footer from '../../components/Footer'
import Header from '../../components/Header'

export default {
  name: 'Home',
  data () {
    return {
      isHeaderPut: false,
      isLogin: true,
      isAbout: false,
      headerBanner: '',
    }
  },
  watch: {
    '$route': function (n) {
      const { path } = n
      if (path != '/index') {
        this.isHeaderPut = true
      } else {
        this.isHeaderPut = false
      }
      if (path === '/login') {
        this.isLogin = false
      } else {
        this.isLogin = true
      }
      if (path === '/about') {
        this.isAbout = true
      } else {
        this.isAbout = false
      }
      console.log(n, 'nnnnnnnnnnnnnnnnnnnn')
      this.pathSwitch(path)
    }
  },
  methods: {
    pathSwitch (path) {
      if (path.indexOf('drama') >= 0) {
        this.headerBanner = 'https://www.jubenchaoshi.cn/img/jbk.jpg'
      } else if (path.indexOf('scenario') >= 0) {
        this.headerBanner = 'https://www.jubenchaoshi.cn/img/bjk.jpg'
      } else if (path.indexOf('production') >= 0) {
        this.headerBanner = 'https://www.jubenchaoshi.cn/img/zzk.jpg'
      } else if (path.indexOf('taskHall') >= 0) {
        this.headerBanner = 'https://www.jubenchaoshi.cn/img/rwdt.jpg'
      } else if (path.indexOf('scriptHall') >= 0) {
        this.headerBanner = 'https://www.jubenchaoshi.cn/img/jbt.jpg'
      } else if (path.indexOf('about') >= 0) {
        this.headerBanner = 'https://www.jubenchaoshi.cn/img/bgw.jpg'
      } else {
        this.headerBanner = 'https://www.jubenchaoshi.cn/img/banner.jpg'
      }
    }
  },
  created() {
    const { path } = this.$route
    console.log(this.$route)

    if (path != '/index') {
      this.isHeaderPut = true
    }
    if (path === '/login') {
      this.isLogin = false
    } else {
      this.isLogin = true
    }
    if (path === '/about') {
        this.isAbout = true
      } else {
        this.isAbout = false
      }
    this.pathSwitch(path)
  },
  components: {
    Footer,
    Header
  }
}
</script>

<style scoped>

</style>
