<template>
	<div class='detail-box'>
		<div class="detail-left">
			<div class="title-name">
				<p>{{detailData.title}}</p>
				<div class="share-btn">
          <img class="share-icon" src="../../assets/images/share.png" alt="" @click='isShare=true' style='cursor:pointer'>
					<!-- <div class="share-icon"></div> -->
				</div>
			</div>

			<div class="table-info">
        <div class="cell">
          <div class="cell-left">负责人</div>
          <div class="cell-right">{{detailData.name}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">联系电话</div>
          <div class="cell-right">{{detailData.phone}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">邮箱</div>
          <div class="cell-right">{{detailData.email}}</div>
        </div>
        <div class="cell">
          <div class="cell-left">公司地址</div>
          <div class="cell-right">{{detailData.city}}</div>
        </div>
      </div>

			<p class="title-tip">公司简介</p>
			<div class="title-desc" v-html='detailData.body'></div>

		</div>
		<div class="detail-right">
			<right-Section />
		</div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="detailData.codeimg" alt="" class='mask-img'>
      </div>
    </div>
	</div>
</template>

<script>
import rightSection from '../../components/RightSearch/right-production'
export default {
  components: {
    rightSection
  },
  data () {
    return {
      detailData: {},
      isShare: false,
    }
  },
  methods: {
    getDetail(id) {
      this.$http.get('/production/itemone', {  
        id
      }).then((res) => {
        this.detailData = res.rows
      })
    },
  },
  created() {
    this.getDetail(this.$route.query.id)
  },
}
</script>

<style scoped lang='less'>
.detail-box {
	display: flex;
  justify-content: center;
  background: #f8f8f8;
  padding-top: 30px;
  padding-bottom: 60px;
}
.detail-left {
	width: 822px;
	background: #ffffff;
	border-radius: 6px;
	padding: 20px;
	display: flex;
	flex-direction: column;

	.title-name {
		display: flex;
		align-content: center;
		justify-content: space-between;
		p {
			font-size: 18px;
			font-family: PingFangSC, PingFangSC-Semibold;
			font-weight: 600;
			text-align: left;
			color: #000000;
		}
		.share-btn {
			display: flex;
			.share-icon {
				width: 17px;
				height: 17px;
				
				margin-left: 18px;
			}
		}
	}
	
	.table-info {
    width: 100%;
    height: auto;
    border: 1px solid #e4e4e4;
    border-bottom: none;
    margin-top: 11px;
    .cell {
      display: flex;
      width: 100%;
      height: 54px;
      border-bottom: 1px solid #e4e4e4;
      .cell-left {
        width: 96px;
        height: 100%;
        border-right: 1px solid #e4e4e4;
        display: flex;
        align-items: center;
        text-indent: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000000;
      }
      .cell-right {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        text-indent: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #000000;
      }
		}
	}

	.title-tip {
    font-size: 18px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
    margin-top: 30px;
  }
}
.detail-right {
	width: 348px;
  margin-left: 30px;
}
.title-desc{
	margin-top: 20px;
  /deep/ img {
    width: 782px;
    display: block;
    margin: 0 auto;
  }
}
</style>