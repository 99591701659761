import Vue from 'vue'	//引入vue
import VueRouter from 'vue-router'	//引入vue-router模块
import routes from './router.js'	//引入路由配置文件(详见下方routes.js配置)
Vue.use(VueRouter)	//声明安装路由
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router =  new VueRouter({
  mode: 'history', // history
  routes
})	//返回实例

export default router
