<template>
  <div class="toast-info " v-if="isShow">
    <img src='../../assets/images/error.png' v-if='type === "normal"'/> <p :class='{error: type === "normal"}'>{{message}}</p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: '',
      isShow: true,
      type: "normal",
      duration: 3000
    }
  },
  mounted() {
    // this.hide();
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.isShow = false;
      }, 2000)
    }
  }
}
</script>
<style lang="less" scoped>
.toast-info {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 14px 41px;
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-items: center;
  align-items: center;

  .error {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #F74B01;
    line-height: 20px;
  }

  img {
    margin-right: 23px;
    width: 22px;
    height: 22px;
  }
}
</style>