<template>
  <div class='new-content'>
    <div class='new-left'>
      <div class='new-title'>
        <p>{{detailData.title}}</p>
        <img src='../../assets/images/share.png' @click='isShare=true' style='cursor:pointer' />
        <!-- <div class='share-content' v-if='isShare'>
          <span @click='isShare=false'>x</span>
          <img :src="detailData.codeimg" alt="">
        </div> -->
      </div>
      <div class='new-data'>
        <div style='display: flex;'>
          <p>{{detailData.time}}</p>
          <p style='margin-left:15px;'>主编： {{detailData.name}}</p>
        </div>
        <p>浏览人数: {{detailData.hot}}</p>
      </div>
      <div class='new-img' v-html='detailData.body'>
      </div>
    </div>
    <div class='new-right'>
      <div class='right-new'>
        <div class='news-title'>推荐新闻</div>
        <div class='news-msg' v-for='(item, key) in detailHot' :key='key'>
          <img :src="item.img" alt="">
          <p class=title>{{item.title}}</p>
          <p class='msg'>{{item.desction}}</p>
        </div>
      </div>
      <div class='black-list'>
        <div class='title'>剧本黑马</div>
        <div class='black-column' v-for='(item, key) in detailBlack' :key='key'>
          <div class='box-img'>
            <img :src="item.img" alt="">
          </div>
          <div class='column-msg'>
            <div>{{item.title}}</div>
            <p>编剧：{{item.name}}</p>
            <p>题材：{{item.classify}}</p>
            <p>简介：{{item.desction}}</p>
          </div>
        </div>
        <div class='black-more' @click='toBlack'>查看更多</div>
      </div>
    </div>
    <div class='mask-content' v-if='isShare'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isShare=false' class='mask-close'>
        <img :src="detailData.codeimg" alt="" class='mask-img'>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detailData: {},
      detailHot: [],
      detailBlack: [],
      isShare: false
    }
  },
  methods: {
    toShare() {
      window.open('https://www.jubenchaoshi.cn/img/jbcs.jpg')
    },
    getDetail(id) {
      this.$http.get('/news/itemone', {  
        id
      }).then((res) => {
        this.detailData = res.rows
      })
    },
    getHot(id) {
      this.$http.get('/news/righthot', {  
        id
      }).then((res) => {
        this.detailHot = res.rows
      })
    },
    getBlack(id) {
      this.$http.get('/drama/righthot', {  
        id
      }).then((res) => {
        this.detailBlack = res.rows
      })
    },
    toBlack() {
      this.$router.push({
        path: '/drama',
        query: {
          pageType: 'blackList'
        }
      })
    },
  },
  created() {
    this.getHot()
    this.getBlack()
    this.getDetail(this.$route.query.id)
  },
}
</script>
<style lang="less" scoped>
.new-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding-top: 48px;
  margin-bottom: 110px;
  .new-right {
    width: 348px;
    .right-new {
      border-radius: 6px;
      padding-left: 20px;
      background: #f5f5f5;
      .news-title {
        padding-top: 20px;
        text-align: center;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        color: #000000;
        line-height: 22px;
      }
      .news-msg {
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
        img {
          width: 308px;
          height: 172px;
          border-radius: 6px;
          display:block;
        }
        .title {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 14px;
          padding: 8px 0;
        }
        .msg {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 14px;
        }
      }
      .more-btn {
        width: 170px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 27px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto;
      }
    }
    .black-list {
      width: 348px;
      border-radius: 7px;
      padding-left: 20px;
      background: #f5f5f5;
      margin-top: 30px;
      
      .title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #000000;
        line-height: 22px;
        padding-top: 20px;
      }

      .black-column {
        display: flex;
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
        .box-img {
          width: 110px;
          height: 140px;
          display: block;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          cursor: pointer;
          img { 
            width: auto;
            height: auto;
            max-height: 100%;
          }
        }

        .column-msg {
          flex: 1;
          div {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Semibold;
            font-weight: 600;
            text-align: left;
            color: #000000;
            line-height: 14px;
          }

          p {
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #999999;
            line-height: 21px;
            margin-top: 6px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }

      .black-more {
        margin: 30px auto 0 auto;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #000000;
        line-height: 20px;
        border: 1px solid #000000;
        border-radius: 27px;
        width: 170px;
        padding: 16px 0;
        cursor: pointer;
      }
    }
  }
  .new-left {
    width: 822px;
    margin-right: 30px;

    .new-img {
      img {
        width: 822px;
        height: 462px;
        display: block;
      }

      p {
        padding: 10px 0;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 21px;
      }
    } 
    .new-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      p {
        font-size: 22px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 18px;
      }
      img {
        width: 18px;
        height: 18px;
      }
      .share-content {
        position: absolute;
        right: 0px;
        top: -245px;
        width: 215px;
        height: 245px;
        background: #fff;
        padding: 10px 20px 20px 20px;
        border-radius: 10px;
        span {
          font-size: 20px;
          color: #000;
          text-align: right;
          padding-right: 10px;
          display: block;
        }
        img {
          display: block;
          width: 175px;
          height: 185px;
        }
      }
    }

    .new-data {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 20px;
    }
  }
}
.new-img{
	margin-top: 20px;
  /deep/ img {
    width: 782px;
    display: block;
    margin: 0 auto;

  }
}
</style>
