<template>
  <div class='right-search'>
    <div class="search-upload">
      <p  v-if='pageType === "blackList"'>当前票数：{{tp ? tp: blackList.tp}}</p>
      <div>
        <div class="upload-btn" @click='toAdd'  v-if='pageType === "blackList"'>投他一票</div>
        <div class="upload-jl" @click='toUpload("https://www.jubenchaoshi.cn/htmlapi/from/contribute")' v-if='pageType === "blackList"'>推荐好剧本</div>
        <div class="upload-jl" @click='toUpload("https://www.jubenchaoshi.cn/htmlapi/from/contribute")' v-else>上传好剧本</div>

      </div>
    </div>

    <div class='right-flow'>
        <div class='flow-title'>购买流程</div>
        <div class='flow-box flow-box-actived'>
          <div>1</div>
          <p>签订保密协议</p>
        </div>
        <div class='flow-box'>
          <div>2</div>
          <p>预览剧本</p>
        </div>
        <div class='flow-box'>
          <div>3</div>
          <p>联系版权经理</p>
        </div>
        <div class='flow-box'>
          <div>4</div>
          <p>支付订金</p>
        </div>
        <div class='flow-box'>
          <div>5</div>
          <p>确定购买</p>
        </div>
        <div class='flow-box'>
          <div>6</div>
          <p>签订著作权转让合约</p>
        </div>
        <div class='flow-box'>
          <div>7</div>
          <p>购买成功</p>
        </div>
        <div class='flow-box'>
          <div>8</div>
          <p>已购买版权下架</p>
        </div>
        
    </div>

    <div class='black-list'>
      <div class='title'>推荐剧本</div>
      <div class='black-column' v-for='(item, key) in blackList.tj' :key='key' @click='goDetail(item.id)'>
        <div class='box-img'>
          <img :src="item.img" alt="">
        </div>
        <div class='column-msg'>
          <div>{{item.title}}</div>
          <p>编剧：{{item.name}}</p>
          <p>题材：{{item.classify}}</p>
          <p>简介：{{item.desction}}</p>
        </div>
      </div>
      
      <div class='black-more' @click='toBlack'>查看更多</div>
    </div>
    <div class='mask-content' v-if='isModel'>
      <div class='mask-box'>
        <img src="../../assets/images/guanbi.png" alt="" @click='isModel=false' class='mask-close'>
        <textarea v-model='content' name="" id="" cols="30" rows="10" placeholder="请输入评论"></textarea>
        <div class='btn' @click='toSubmit'>提交</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['isJoin', 'blackList', 'pageType'],
  data () {
    return {
      tp: '',
      content: '',
      isModel: false
    }
  },
  methods: {
    toUpload(url) {
      window.open(url)
    },
    goDetail(id) {
      this.$router.push({
        path:'/drama/detail',
        query: {
          id,
          pageType: this.pageType
        }
      })
    },
    toSubmit() {
      this.$http.post('/blackList/addly', {  
        id: this.$route.query.id,
        body: this.content,
      }).then((res) => {
        this.$toast(res.msg, 'none')
        this.isModel = false
      })
    },
    toAdd() {
      this.$http.post('/blackList/add', {  
        id: this.$route.query.id
      }).then((res) => {
        this.$toast(res.msg, 'none')
        this.tp = res.rows
        this.isModel = true
      })
    },
    toBlack() {
      this.$router.push({
        path: '/drama',
        query: {
          pageType: 'blackList'
        }
      })
    }
  },
}
</script>

<style scoped lang='less'>

.right-flow{
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 6px;

  .flow-title {
    font-size: 16px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    text-align: left;
    color: #000000;
    line-height: 16px;
  }

  .flow-box {
    display: flex;
    margin-top: 20px;
    width: 165px;
    div {
      width: 17px;
      height: 17px;
      background: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 16px;
      border: 1px solid #000;
    }
    
    p {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 14px;
      margin-left: 10px;
    }
  }

  .flow-box-actived {
    div {
      background: #000000;
      color: #fff;
    }
  }
}
.right-search {
  width: 348px;

  .search-input {
    width: 348px;
    height: 34px;
    border-radius: 18px;
    border: 1px solid #000;
    text-indent: 14px;
    margin-top: 60px;
    font-size: 14px;
  }

  .search-upload {
    width: 348px;
    // height: 238px;
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-radius: 7px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;

    .upload-btn {
			margin: 20px 0;
      width: 156px;
      height: 50px;
      border-radius: 26px;
      background: #000000;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .upload-jl {
      width: 156px;
      height: 50px;
      border-radius: 26px;
      border: 1px solid #000;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 30px;
			font-family: PingFangSC, PingFangSC-Semibold;
			font-weight: 600;
			color: #000000;
    }
  }

  .black-list {
    width: 348px;
    border: 1px solid #e4e4e4;
    border-radius: 7px;
    padding: 20px;
    margin-top: 30px;
    background: #fff;
    
    .title {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: center;
      color: #000000;
      line-height: 22px;
    }

    .black-column {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      .box-img {
        width: 110px;
        height: 140px;
        display: block;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        img { 
          width: auto;
          height: auto;
          max-height: 100%;
        }
      }

      .column-msg {
        flex: 1;
        div {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #000000;
          line-height: 14px;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 21px;
          margin-top: 6px;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

    .black-more {
      margin: 30px auto 0 auto;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #000000;
      line-height: 20px;
      border: 1px solid #000000;
      border-radius: 27px;
      width: 170px;
      padding: 16px 0;
      cursor: pointer;
    }
  }
}
</style>
