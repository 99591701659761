<template>
  <div class='index-view'>
    <!-- <div class='index-banner' v-if='indexBanner.list.length'>
      <div class='banner-column' v-for='(item ,key) in indexBanner.list' :key=key @click='toNewDetail(item.id)'>
        <div class='box-img'>
          <img :src="item.img" alt="">
        </div>
        <div class='title' :title='item.title'>{{item.title}}</div>
        <p :title='item.desction'>{{item.desction}}</p>
      </div>

    </div> -->
    <div class='swiper-container-banner swiper-container' style='overflow: hidden'>
      <div class='swiper-wrapper index-banner'> 
        <div class='banner-column swiper-slide' v-for='(item ,key) in indexBanner.list' :key='key' @click.stop='toNewDetail(item.id)' >
          <div class='box-img'>
            <img :src="item.img" alt="">
          </div>
          <div class='title' :title='item.title'>{{item.title}}</div>
          <p :title='item.desction'>{{item.desction}}</p>
        </div>
      </div>
    </div>

    <div class='index-dots' v-if='indexBanner.total'>
      <p class='swiper-prev-box'>
        <img src="https://www.jubenchaoshi.cn/img/gd1.png" alt="" @click.stop='toChangeSwiper("banner", "prev")'>
      </p>
      <p class='swiper-next-box'>
        <img src="https://www.jubenchaoshi.cn/img/gd2.png" alt="" @click.stop='toChangeSwiper("banner", "next")'>
      </p>
      <div :class='{"actived": indexBanner.actived == key}' v-for='(item, key) in indexBanner.total' :key='key' @click='toBanner(key)'></div>
    </div>

    <div class='exclusive-services'>
      <div class='index-title'>
        <div>专属服务</div>
        <p>—————{{indexService.totalNum}}项专属服务让影视版权发挥最大价值—————</p>
      </div>
    </div>

    <div class='swiper-container swiper-container-services' style='overflow: hidden;' >
      <div class='swiper-wrapper service-box'>
        <div class='swiper-slide service-slide' v-for='(item ,key) in indexService.list' :key='key' @click='toService(item.id)'>
          <div class='service-img'>
            <img :src="item.img" alt="">
          </div> 
          <!-- <p>{{item.title}}</p> -->
        </div>
      </div>
    </div>

   
    <div class='index-dots' v-if='indexService.list.length'>
      <p class='swiper-prev-box' style='top: -85px;'>
        <img src="https://www.jubenchaoshi.cn/img/gd1.png" alt="" @click.stop='toChangeSwiper("service", "prev")'>
      </p>
      <p class='swiper-next-box' style='top: -85px;'>
        <img src="https://www.jubenchaoshi.cn/img/gd2.png" alt="" @click.stop='toChangeSwiper("service", "next")'>
      </p>
      <div :class='{"actived": indexService.actived == key}' v-for='(item, key) in indexService.total' :key='key' @click='toServices(key)'></div>
    </div>
    <div class='index-more' @click='toPath("/services")'>
      查看更多
    </div>

    <div class='blackList-content' v-if='indexBlack.list.length'>
      <div class='index-title'>
        <div>剧本黑马</div>
        <p>一剧之本，创造价值</p>
      </div>
      <div class='blackList-box'>
        <div class='box-column' v-for='(item, key) in indexBlack.list' :key='key' @click='goDramaDetail(item.id)'>
          <div class='box-img'>
            <img :src="item.img" alt="">
          </div>
          <div class='title'>{{item.title}}</div>
          <p>{{item.name}}</p>
          <p>{{item.theme}}</p>
        </div>
      </div>
      <div class='index-more' @click='toBlackList'>
        查看更多
      </div>
    </div>

    <div class='transaction-information' v-if='indexInformation.list.length'>
      <div class='index-title'>
        <div>成交信息</div>
        <p>—————剧本超市成功案例—————</p>
      </div>
      <div class='information-box'>
        <div class='box-column' v-for='(item, key) in indexInformation.list' :key='key' @click='toSuccess'>
          <div class='box-img'>
            <img :src="item.img" alt="">
          </div>
          <div class='column-msg'>
            <div class='column-type'>{{item.tag}}</div>
            <div class='column-name'>{{item.title}}</div>
            <!-- <div class='column-collection'>
              <p>浏览人数: {{item.hot}}</p>
            </div> -->
          </div>
          <div class='box-bottom'>
            <div>
              <!-- <p>{{item.price}}</p> -->
              <p class='price'>成交公司：{{item.join}}</p>
            </div>
            <div style='font-size:18px;'>
              {{item.state}}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class='index-dots'>
        <div :class='{"actived": indexInformation.actived == key}' v-for='(item, key) in indexInformation.total' :key='key' @click='toInformation(key)'></div>
      </div> -->
    </div>
    <div class='index-more' @click='toPath("/success")'>
      查看更多
    </div>

    <div class='tv-play' v-if='indexTv.list.length'>
      <div class='tv-nav'>
        <div @click='toScenarioAll'>全部</div>
        <div v-for='(item, key) in typeList' :key='key' @click='toScenario(item.id)'>{{item.name}}</div>
      </div>
      <div class='tv-box'>
        <div class='box-column' v-for='(item,key) in indexTv.list' :key='key' @click='goDramaDetail(item.id)'>
          <div class='column-img'>
            <img :src="item.img" alt="">
          </div>
          <div class='column-type'>{{item.tag}}</div>
          <div class='column-name'>{{item.title}}</div>
          <p>作者：{{item.name}}</p>
          <p>题材：{{item.theme}}</p>
        </div>
      </div>
    </div>
    <div class='index-more' @click='toPath("/drama")' style='margin-bottom:80px;'>
      查看更多
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination } from "swiper"
Swiper.use([Pagination])

export default {
  name: 'Index',
  data () {
    return {
      indexBanner: {
        list: [],
        total: 0,
        actived: 0
      },
      indexService: {
        list: [],
        total: 0,
        actived: 0,
        totalNum: 0
      },
      indexBlack: {
        list: [],
        total: 0,
        actived: 0
      },
      indexInformation: {
        list: [],
        total: 0,
        actived: 0
      },
      indexTv: {
        list: [],
        total: 0,
        actived: 0
      },
      typeList: [],
      bannerSwiper: null,
      serviceSwiper: null,
      bannerSwiperIndex: 0,
      serviceSwiperIndex: 0,
    }
  },
  methods: {
    toChangeSwiper(type, doc) {
      if (type === 'banner') {
        if (doc === 'prev') {
          const ind = (this.bannerSwiperIndex - 1) <=0 ? 0 : this.bannerSwiperIndex -1
          this.bannerSwiper.slideTo(ind)
        } else {
          const ind = (this.bannerSwiperIndex + 1) >= this.indexBanner.list.length ? this.indexBanner.list.length : this.bannerSwiperIndex +1
          console.log(ind)
          this.bannerSwiper.slideTo(ind)
        }
      } else {
        if (doc === 'prev') {
          const ind = (this.serviceSwiperIndex - 1) <=0 ? 0 : this.serviceSwiperIndex - 1
          this.serviceSwiper.slideTo(ind)
        } else {
          const ind = (this.serviceSwiperIndex + 1) >=this.indexService.list.length ? this.indexService.list.length : this.serviceSwiperIndex + 1
          this.serviceSwiper.slideTo(ind)
        }
      }
    },
    toSuccess() {
      this.$router.push({
        path:'/success',
      })
    },
    toService(id) {
      this.$router.push({
        path:'/content',
        query: {
          id
        }
      })
    },
    toScenario(key) {
      this.$router.push({
        path:'/drama',
        query: {
          id: key
        }
      })
    },
    toScenarioAll() {
      this.$router.push({
        path:'/drama'
      })
    },
    goDramaDetail(id) {
      this.$router.push({
        path:'/drama/detail',
        query: {
          id
        }
      })
    },
    toNewDetail(id) {
      this.$router.push({
        path: 'newDetail',
        query: {
          id
        }
      })
    },
    toPath(path) {
      this.$router.push({
        path
      })
    },
    toBlackList() {
      this.$router.push({
        path: '/drama',
        query: {
          pageType: 'blackList'
        }
      })
    },
    getItemIndexTv(pageNum, actived) {
      this.$http.get('/index/item5', {  
        pageSize: 10,
        pageNum: 1
      }).then((res) => {
        this.indexTv = {
          list: res.rows,
          total: Math.round(res.total / 4),
          actived
        }
      })
    },
    getItemIndexInformation(pageNum, actived) {
      this.$http.get('/index/item4', {  
        pageSize: 4,
        pageNum
      }).then((res) => {
        this.indexInformation = {
          list: res.rows,
          total: Math.round(res.total / 4),
          actived
        }
      })
    },
    getItemIndexBlack(pageNum, actived) {
      this.$http.get('/index/item3', {  
        pageSize: 6,
        pageNum
      }).then((res) => {
        this.indexBlack = {
          list: res.rows,
          total: Math.round(res.total / 4),
          actived
        }
      })
    },
    getItemServices (pageNum, actived) {
      this.$http.get('/index/item2', {  
        pageSize: 36,
        pageNum
      }).then((res) => {
        this.indexService = {
          list: res.rows,
          total: Math.round(res.total / 6),
          totalNum: res.total,
          actived
        }
      })
    },
    getItemBanner (pageNum, actived) {
      this.$http.get('/index/item1', {  
        pageSize: 24,
        pageNum
      }).then((res) => {
        this.indexBanner = {
          list: res.rows,
          total: 6,
          actived
        }
      })
    },
    toBanner(key) {
      this.bannerSwiper.slideTo((key*4))
      this.indexBanner.actived = key
      // this.getItemBanner(key+1, key)
    },
    toServices(key) {
      this.serviceSwiper.slideTo((key*6))
      this.indexService.actived = key
      // this.getItemServices(key+1, key)
    },
    toIndexBlack(key) {
      this.getItemIndexBlack(key+1, key)
    },
    toIndexInformation(key) {
      this.getItemIndexInformation(key+1, key)
    },
    toIndexTv(key) {
      this.getItemIndexTv(key+1, key)
    },
    initSwiper() {
      const _this = this
      this.serviceSwiper = new Swiper('.swiper-container-services', {
        loop: true,
        observer: true,
        observeParents: true,
        spaceBetween: 30,
        slidesPerView: 6,
        on:{
          slideChange: function(){
            _this.serviceSwiperIndex = this.activeIndex
            _this.indexService.actived = Math.floor(this.activeIndex/6)
          },
        },
      })
      this.bannerSwiper = new Swiper('.swiper-container-banner', {
        loop: true,
        observer: true,
        observeParents: true,
        spaceBetween: 30,
        slidesPerView: 4,
        on:{
          slideChange: function(){
            _this.bannerSwiperIndex = this.activeIndex
            _this.indexBanner.actived = Math.floor(this.activeIndex/4)
            // alert('改变了，activeIndex为'+this.activeIndex);
          },
        },
      })
    },
    getType() {
      this.$http.get('/tag/item/uid/0', {  
      }).then((res) => {
       this.typeList = res.rows;
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initSwiper()
    })
  },
  created() {
    console.log(process.env.NODE_ENV, '当前环境')
    this.getItemBanner(1, 0)
    this.getItemServices(1, 0)
    this.getItemIndexBlack(1, 0)
    this.getItemIndexInformation(1, 0)
    this.getItemIndexTv(1, 0)
    this.getType()
  }
  // /api
}
</script>

<style scoped lang='less'>
.index-view {
    width: 1200px;
    margin: 0 auto;
    background: #f8f8f8;
}

.page-content {
  margin-top: 30px;
  margin-bottom: 60px;
}
// 电视剧
.tv-play {
  .tv-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    div {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 22px;
      padding: 0 20px;
      cursor:pointer;
      &:not(:first-child) {
        border-left: 1px solid #000;
      }
    }

    p {
      width: 1px;
      height: 12px;
      margin: 0 20px;
      background: #000;
    }
  }

  .tv-box {
    display: flex;
    flex-wrap: wrap;

    .box-column {
      position: relative;
      margin-top: 30px;
      cursor: pointer;
      
      &:hover {
        transform: translateY(-10px);
      }
      &:not(:nth-child(5n+1)) {
        margin-left: 30px;
      }
      // &:nth-child(n+6) {
      // }
      img {
        display: block;
      }

      .column-img {
        width: 216px;
        height: 288px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          width: auto;
          height: auto;
          // max-width: 100%;
          max-height: 100%;
        }
      }
      .column-type {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 30px;
        width: 96px;
        height: 30px;
        opacity: 0.4;
        background: #000000;
        border-radius: 6px 0px 6px 0px;
      }

      .column-name {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: center;
        color: #000000;
        line-height: 14px;
        margin-top: 8px;
      }

      p {
        margin-top: 8px;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #999999;
        line-height: 12px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        width: 216px;
      }


    }
  }
}

// 成交信息
.transaction-information {
  .information-box {
    display: flex;

    .box-column {
      &:hover {
        box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.10); 
        background: #f5f5f5;
        transform: translateY(-10px);
      }
      border-radius: 6px;
      border: 1px solid #ddd;
      position: relative;
      background: #fff;
      cursor: pointer;

      &:not(:first-child) {
        margin-left: 30px;
      }
      .box-img {
        width: 278px;
        height: 200px;
        display: block;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: auto;
          height: auto;
          max-height: 100%;
        }
      }

      .box-bottom {
        display: flex;
        border-top: 1px solid #ddd;
        div {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-family: PingFangSC, PingFangSC-Semibold;
          font-weight: 600;
          text-align: left;
          color: #e02020;
          line-height: 30px;

          &:not(:first-child) {
            border-left: 1px solid #ddd;
          }

          p {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #e02020;
            line-height: 17px;
          }

          .price {
            color: #999999;
          }
        }
      }

      .column-msg {
        padding: 5px 10px 10px 10px;
      }

      .column-type {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        line-height: 30px;
        width: 96px;
        height: 30px;
        opacity: 0.4;
        background: #000000;
        border-radius: 6px 0px 6px 0px;
      }

      .column-name {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 600;
        text-align: left;
        color: #000000;
        line-height: 22px;
      }
      // 浏览/收藏
      .column-collection {
        display: flex;
        justify-content: space-between;

        img {
          width: 14px;
          height: 14px;
          display: block;
        }

        p {
          font-size: 12px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 17px;
        }
      }
    }
  }
}

// 剧本黑马
.blackList-box {
  display: flex;
  .box-column {
    border: 1px solid #ddd;
    border-radius: 6px;
    width: 175px;
    height: 255px;
    padding: 20px 33px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.10); 
      background: #f5f5f5;
    }
    &:not(:first-child) {
      margin-left: 30px;
    }

    .box-img {
      width: 115px;
      height: 153px;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 6px;

      img {
        width: auto;
        height: auto;
        max-height: 100%;
      }
    }


    .title {
      margin-top: 8px;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 14px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

    p {
      width: 115px;
      margin-top: 8px;
      font-size: 12px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: center;
      color: #999999;
      line-height: 11px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

    
  }
}

.index-more {
  margin: 30px auto 0 auto;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #000000;
  line-height: 20px;
  padding: 15px 0;
  border-radius: 26px;
  width: 156px;
  border: 1px solid #000;
  cursor: pointer;
}

// 专属服务
.exclusive-services {

}
.service-box{
  display: flex;

  .service-slide {
    width: 175px;
    height: 132px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #ffffff;
    line-height: 28px;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    
    .service-img {
      width: 175px;
      height: 132px;
      overflow:hidden;
      border-radius: 6px;
      transition: transform .5s;
      &:hover{
        img {
        transform: scale(1.2);
        }
      }
      img {
        width: 175px;
        height: 132px;
        border-radius: 6px;
        transition: transform .5s;
      }
    }
    // &:after {
    //   content: '';
    //   height: 0;
    //   line-height: 0;
    //   display: block;
    //   visibility: hidden;
    //   clear: both;
    // }
    

   

    // &:not(:first-child) {
    //   margin-left: 30px;
    // }
  }

  .first {
    font-size: 28px;
    line-height: 40px;
  }
}

// banner部分
.index-banner {
  width: 100%;
  margin-top: 60px;
  display: flex;

  .banner-column {
    width: 278px;
    cursor: pointer;
    &:hover {
      transform: translateY(-10px);
    }
    // &:not(:first-child) {
    //   margin-left: 30px;
    // }
    
    .box-img {
      width: 278px;
      height: 156px;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 10px;

      img {
        width: auto;
        height: auto;
        max-height: 100%;
      }
    }
    .title {
      margin: 8px 0;
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 16px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

    p {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      line-height: 14px;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }
}

.index-title {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 30px;

  div {
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Semibold;
    font-weight: 600;
    color: #000000;
    line-height: 20px;
  }

  p {
    margin-top: 8px;
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
}

.index-dots {
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  position: relative;

  div {
    width: 20px;
    height: 4px;
    border-radius: 2px;
    background: #ddd;
    margin-right: 20px;
    cursor: pointer;
  }
  .actived {
    background: #000;
  }
}

.swiper-prev-box {
  position: absolute;
  left: -44px;
  top: -140px;
  img {
    width: 22px;
    height: 39px;
  }
}
.swiper-next-box {
  position: absolute;
  right: -44px;
  top: -140px;
  img {
    width: 22px;
    height: 39px;
  }
}
</style>
