<template>
  <div class='content-box'>
    <div class='content-time'>
      <p>{{detailData.time}}</p>
      <div>
        <!--<img src="../../assets/images/phone.png" alt="">
        <img src="../../assets/images/wx.png" alt="">-->
      </div>
    </div>
    <div class='msg-p' style='margin-top:30px;'>
      <div class='title' >{{detailData.title}}</div>
    </div>
    
    <div class='banner' v-html='detailData.body'>
    </div>
    <div class='content-msg'>
      <a :href="detailData.xdurl" target="_blank" class="xxd">立即下单</a>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      detailData: {}
    }
  },
  methods: {
    getDetail(id) {
      this.$http.get('/serve/itemone', {  
        id
      }).then((res) => {
        this.detailData = res.rows
      })
    },
  },
  created() {
    this.getDetail(this.$route.query.id)
  },
}
</script>
<style lang="less" scoped>
.content-box {
  width: 1200px;
  margin: 0 auto;
  .content-time{ 
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #000000;
      line-height: 20px;
    }
    div {
      display:flex;
      img {
        width: 20px;
        height: 20px;
        display: block;
        margin-left: 10px;
      }
    }
  }
  .img-two {
    display: flex;
    justify-content: space-between;
    margin-top:10px;
    img {
      width: 585px;
      height: 468px;
      display: block;
    }
  }
  .img-three {
    display: flex;
    justify-content: space-between;
    margin-top:10px;
    img {
      width: 380px;
      height: 285px;
      display: block;
    }
  }
  .msg-p {
    padding-top: 60px;
    .title {
      font-size: 22px;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: 600;
      text-align: left;
      color: #000000;
      line-height: 18px;
    }
    p {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 21px;
      margin-top: 10px;
    }
  }
  .content-msg {
    width: 170px;
    height: 52px;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 20px;
    background: #000000;
    border-radius: 26px;
    margin: 60px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.banner{
	margin-top: 20px;
  /deep/ img {
    width: 782px;
    display: block;
    margin: 0 auto;
  }
}
.xxd{
  color: #fff;
  text-decoration:none;
}
</style>
