<template>
  <div class='search-content'>
    <div class='title'>用户类型</div>
    <div class='type-list'>
      <div v-for='(item, key) in detailData.type' :key='key' :class='{"actived": item.xz === 1}' @click='toCheck("type", key)'>{{item.name}}</div>
    </div>
    <div class='title'>类别</div>
    <div class='type-list'>
      <div v-for='(item, key) in detailData.classify' :key='key' :class='{"actived": item.xz === 1}' @click='toCheck("classify", key)'>{{item.name}}</div>
    </div>
    <div class='title'>类型<span>（点击选中 再点取消）</span></div>
    <div class='type-list'>
      <div v-for='(item, key) in detailData.theme' :key='key' :class='{"actived": item.xz === 1}' @click='toCheck("theme", key)'>{{item.name}}</div>
    </div>

    <div class='type-list' style='justify-content: center;margin-top: 50px;'>
      <div class='actived' style='cursor:pointer' @click='toSubmit'>提交</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      detailData: {}
    }
  },
  methods: {
    toCheck(type, key) {
      this.detailData[type][key].xz = this.detailData[type][key].xz === 1 ? 0 : 1
    },
    getDetail(id) {
      this.$http.get('/userinfo/myxh', {  
        id
      }).then((res) => {
        this.detailData = res.rows
      })
    },
    toSubmit() {
      this.$http.post('/userinfo/myxh_add', {  
        type: this.detailData.type.filter((val) => val.xz).map((val) => val.id).join(','),
        classify: this.detailData.classify.filter((val) => val.xz).map((val) => val.id).join(','),
        theme: this.detailData.theme.filter((val) => val.xz).map((val) => val.id).join(','),
      }).then((res) => {
        this.$toast(res.msg, 'none')
      })
    }
  },
  created() {
    this.getDetail(this.$route.query.id)
  },
}
</script>
<style lang="less" scoped>
.search-content{
  width: 1200px;
  margin: 0 auto;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 25px 55px;
  .title {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 30px;
    margin-bottom: 12px;
  }
  .type-list {
    display: flex;
    flex-wrap: wrap;
    div {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      padding: 6px 19px;
      border-radius: 5px;
      display:inline-block;
      border: 1px solid #000000;
      margin-bottom: 20px;
      margin-right: 30px;
    }
    .actived {
      background: #000;
      color: #fff;
    }
  }
}
</style>